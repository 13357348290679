@import "../../../DesignSystem/library";

#DatePickerComponent.datepicker-container {
  height: 100%;
  width: 100%;

  .input-label {
    margin-bottom: 8px;
    font-size: 1.4rem;
    font-weight: 400;
    color: $gray900;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 3.6rem;
    border-radius: 0.4rem;
    padding: 0.4rem 0.8rem;
    padding-left: 1.4rem;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    background: $lightGrayishBlue;

    .placeholder {
    }

    .picker {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
