@import "../../../DesignSystem/library";

.stats-tile-component {
  min-height: 18rem;
  background-color: $light;
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.2rem;
  border-radius: 0.8rem;

  &-headline {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
  }

  &-values {
    font-size: 2.6rem;
    font-weight: 500;

    sub {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }

  &-progress {
    .indicator {
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 5px;

      &-success {
        background-color: $success-light;
      }

      &-error {
        background-color: $error-light;
      }
    }

    span {
      font-size: 1.2rem;
      font-weight: 400;
      margin-right: 5px;
    }

    .graph-section {
      padding: 2px 4px;
      border-radius: 4px;

      &-success {
        background-color: #79ac2e0f;
        border: 2px solid #79ac2e1f;

        span {
          color: $success-light;
          margin-right: 5px;
        }
      }

      &-error {
        background-color: #f9545e0f;
        border: 2px solid #f9545e1f;

        span {
          color: $error-light;
          margin-right: 5px;
        }

        svg {
          transform: rotate(180deg);

          path {
            fill: $error-light;
          }
        }
      }
    }
  }
}
