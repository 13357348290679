.background-screen-filter {
    position: fixed;
    top: 0;
    right: 0;
    height: 100dvh;
    width: 100dvw;
    margin-left: 10rem;
    display: grid;
    place-items: center;
    background-color: #CCCCCC80;
    //backdrop-filter: blur(1px);

    z-index: 100;

}