.header-container {
  width: 100%;

  .menu-bar-icon-container {
    display: flex;
    align-items: center;
    justify-content: right;

    .menu-bar-globe-icon,
    .menu-bar-notiification-icon {
      margin-right: 2rem;
      margin-top: 1rem;

      svg {
      }
    }
  }
}
