@import "../../../DesignSystem/library";

.toggle-switch-component {
  display: flex;
  width: 100%;
  gap: 0;
  border-radius: 6px;
  background-color: $light;
  margin-bottom: 1rem;

  .toggle-switch-button {
    width: 100%;
  }

  button[type="button"] {
    min-width: 5rem;
    // text-transform: uppercase;
    font-size: 1.4rem;
    padding: 1rem 0.8rem;
    margin: 0.4rem;
    border-radius: 6px;
    min-height: 3rem;
    background-color: $light;

    &:focus {
      outline: 2px solid $primary100;
    }
  }

  .active {
    background-color: $primary500 !important;
    background-color: $light;
    color: $light;
  }
}
