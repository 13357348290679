@import "../../../DesignSystem/library";

.multi-select-container {
  position: relative;
  cursor: pointer;
  .selector-dropdown {
    width: 100%;
    min-height: 35px;
    // max-height: 120px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: $lightGrayishBlue;
    color: #b7b7b7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0.5rem;

    .selected-options-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 0.5rem;
      min-height: 30px;
      max-height: 100px;

      .selected-option {
        display: flex;
        align-items: center;
        padding: 0.3rem 0.5rem;
        border-radius: 4px;
        background: rgba(12, 129, 168, 0.08);
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        font-size: 12px;

        svg {
          cursor: pointer;
          margin-left: 0.2rem;
          path {
            fill: #a6a6a6;
          }
        }
      }
    }

    .selectDropdownIcon {
      display: flex;
      cursor: pointer;
      svg {
        transform: rotate(90deg);
        path {
          fill: #666666;
        }
      }
    }

    .rotate-icon {
      svg {
        transform: rotate(-90deg);
        path {
          fill: #666666;
        }
      }
    }
  }

  .selector-dropdown-body {
    width: 100%;
    margin-top: 0.1rem;
    background-color: $lightGrayishBlue;
    border: 1px solid $lightGrayishBlue;
    position: absolute;
    z-index: 1;
    border-radius: 4px;
    align-items: center;
    padding: 0rem 0.3rem;
    padding-bottom: 0.3rem;

    min-height: 44px;
    max-height: 200px;
    overflow-y: scroll;

    .selector-dropdown-body-element {
      background-color: #ffffff;
      border-radius: 4px;
      min-height: 35px;
      margin-top: 0.3rem;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.3rem;

      .selector-dropdown-body-element-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;

        input[type="checkbox"] {
          @include customCheckbox;
        }
        input[type="checkbox"]:checked {
          @include customCheckbox;
        }
      }

      .selector-dropdown-body-element-option {
        margin-left: 0.4rem;
        font-size: 12px;
      }

      &:hover {
        background-color: $lightGrayishBlue;
        cursor: pointer;
      }
    }
  }
}
