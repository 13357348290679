$success50: #eafce8;
$success100: #d5fad1;
$success200: #aaf5a3;
$success300: #80f075;
$success400: #55eb47;
$success500: #2be51a;
$success600: #22b814;
$success700: #1a8a0f;
$success800: #115c0a;
$success900: #092e05;
$success950: #041703;


$success-light: #79AC2E;