@import "../../../DesignSystem/library";

.filter-section {
  height: 100%;
  padding: 1rem 0rem;

  b {
    font-size: 20px;
    font-weight: 500;
  }

  .radio-btns-section {
    display: flex;
    flex-direction: column;
    div {
      margin-top: 1rem;

      input[type="radio"] {
        accent-color: $primary500;
      }
    }
  }

  .partitioner-line {
    border-bottom: 1px solid #f0f1f2;
    width: 100%;
    margin: 1rem auto;
  }
}
.filter-section-body {
  .filter-section-body-title {
    margin-top: 1.3rem;
    b {
      font-family: 500;
    }
  }

  .filter-section-body-fitler-container {
    height: 580px;
    .filter-section-body-fitler {
      margin-bottom: 2rem;

      .filter-section-body-fitler-title {
        margin-bottom: 1rem;
        margin-top: 2rem;
      }
    }

    .filter-section-body-time-filter {
      width: 100%;
      .filter-section-body-time-fitler-title {
        margin-top: 1.3rem;
        margin-bottom: 1rem;
      }
      .time-period-container {
        width: 100%;
        min-height: 35px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        background: $lightGrayishBlue;
        color: #b7b7b7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.3rem;

        .dropdown-container {
          height: 30px !important;
          border-radius: 4px !important;
          svg {
            path {
              fill: #666666;
            }
          }
        }
        .dropdown-body {
          margin-top: 0.2rem;
          max-height: 150px;
          overflow-y: scroll;
        }
      }
    }
  }
}
