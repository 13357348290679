@import "../../../../DesignSystem/library";

#InvesteeDashboardComponent.investee-dashboard-container {
  padding: 2rem;
  overflow-y: scroll;

  .header-stats-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;
    margin-bottom: 1.6rem;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;

      .label {
        font-size: 1.4rem;
        color: $neutral400;
      }

      .value {
        font-size: 1.4rem;
        font-weight: 500;
        color: $neutral1000;
      }
    }
  }

  .tile {
    background-color: $light;
    border-radius: 0.8rem;
    padding: 1.6rem;
    box-shadow: 0px 0.4px 1.3px 0px rgba(0, 0, 0, 0.03),
      2.7px 0px 20.5px 0px rgba(0, 0, 0, 0.06);

    .label {
      margin: 1.2rem;
      font-weight: 500;
      font-size: 1.6rem;
    }
  }

  .stats-container {
    margin-top: 30%;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $neutral400;
      margin-bottom: 1.2rem;

      .cell {
        width: 32%;
      }
    }

    .body-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      .cell {
        width: 32%;
        display: flex;
        align-items: center;
        gap: 0.4rem;

        .icon {
          height: 1.6rem;
          width: 1.6rem;
          border-radius: 50%;
        }
      }
    }
  }

  .stats-tile-container {
    width: 100%;

    .asset-stats-container {
      width: 100%;
      display: flex;
      gap: 0.8rem;
      flex-wrap: wrap;

      .stats-tile {
        min-height: 5rem;
        width: 48%;
        border-radius: 0.8rem;
        padding: 0.8rem;
        display: flex;
        gap: 0.8rem;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 1.2rem;
          width: 5rem;
        }

        .content {
          .stats-label {
            font-size: 1.3rem;
            color: $neutral400;
          }

          .stats-value {
            font-size: 1.6rem;
            font-weight: 500;
            color: $neutral1000;

            span {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
