@import '../../../DesignSystem//library';

#paginationComponent.pagination-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;

    .block {
        min-height: 3.2rem;
        min-width: 3.2rem;
        background-color: #ed4f;
        margin: 0.4rem;
        padding: 0.4rem;
        @include container-flex;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .page-numbers-container {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .active {
        background-color: orangered;
    }
}