@import "../../../DesignSystem/library";

#drodownComponentV2.dropdown-container {
  width: 100%;
  position: relative;
  cursor: pointer;

  .input-label {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: 400;
    color: $gray900;
  }

  .selected-item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3.6rem;
    border-radius: 0.4rem;

    padding: 0.4rem 0.8rem;
    padding-left: 1.4rem;

    border-radius: 6px;
    border: 1px solid #e6e6e6;

    background: $lightGrayishBlue;

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      img {
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
      }

      span {
        font-size: 1.4rem;
      }
    }

    .chevron-icon {
      path {
        fill: #252526;
      }
    }
  }

  .dropdown-list {
    position: absolute;
    top: 105%;
    right: 0%;
    width: 100%;
    background-color: $light;
    border-radius: 0.4rem;
    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
      0px 8px 18px -6px rgba(24, 39, 75, 0.12);
    padding: 0.4rem 0.8rem;
    z-index: 1;
    overflow-y: scroll;
    max-height: 25rem;

    .input-field {
      border: unset;
      border-bottom: 1px solid $background-gray;

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .item {
      padding: 0.4rem 0.8rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin: 0.4rem 0.8rem;
      border-radius: 0.4rem;

      &:hover {
        background-color: $background-gray;
      }

      img {
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
      }

      span {
        font-size: 1.4rem;
      }
    }

    .active {
      background-color: $primary100;
      color: white;

      &:hover {
        background-color: unset;
        color: white;
        background-color: $primary100;
      }
    }

    .add-new {
      .input-field {
        border: unset;
      }

      .cross-icon {
        transform: rotate(45deg);

        width: 2rem;
        height: 2rem;
      }
    }
  }
}
