@import "../../../DesignSystem/library";

#InvesteeDatacollectionComponent.investee-data-collection-form {
  .data-collection-wrapper {
    height: 90dvh;
    padding: 0;

    .wrapper {
      padding: 2rem;

      .content-header {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1.2rem;
      }

      .questionnaire-section {
        margin-bottom: 2.8rem;

        &-header {
          font-size: 2rem;
          font-weight: 500;
          margin-bottom: 1.6rem;
        }

        &-label {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 1.2rem;
        }

        &-sub-questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.8rem;

          #drodownComponentV2.dropdown-container {
            .selected-item {
              height: unset;
            }
          }
        }
      }
    }

    .footer-action-btns {
      position: sticky;
      bottom: 0%;

      .btn-container {
        margin-right: 2rem;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.2rem;
      }

      background-color: $light;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.04);
      height: 6rem;
    }
  }
}
