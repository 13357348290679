@import "../../../DesignSystem/library";

#dropdown.dropdown-menu-component {
  display: flex;
  flex-direction: column;
  position: relative;

  .dropdown-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    height: 4rem;
    align-items: center;
    min-width: 8rem;
    @include tile-white;
    border-radius: 0.8rem;
    padding: 0.4rem 1.6rem;
    box-shadow: $shadow200;

    .chevron-container {
      transform: rotate(90deg);

      .chevron {
        &-active {
          transform: rotateY(180deg);
          transition: all 0.2s ease-in;
        }

        &-inactive {
          transform: rotateY(0deg);
          transition: all 0.2s ease-in;
        }
      }
    }
  }

  .dropdown-disabled {
    background-color: #e0e0e0a8;
    cursor: not-allowed;
  }

  .display-title-placeholder {
    color: gray;
  }

  .dropdown-content-list {
    z-index: 1;
    box-shadow: $shadow200;
    position: absolute;
    top: 100%;
    right: 100%;
    margin-right: -100%;
    @include tile-white;
    border-radius: 0.8rem;
    padding: 0.4rem 0.8rem;
    min-width: 8rem;
    width: 100%;

    .dropdown-input-container-parent {
      position: sticky;

      .dropdown-input-container {
        display: flex;
        padding: 1rem 0rem;

        svg {
          transform: rotate(90deg);
          height: 20px;
        }
      }

      .dropdown-search-partitioner {
        width: 95%;
        border-bottom: 1px solid #e6e6e6;
      }
    }

    .list-content {
      padding: 0.4rem 1.6rem;
      margin: 0.4rem 0;
      background-color: $light;
      border-radius: 0.4rem;
      cursor: pointer;

      &:hover {
        background-color: $background-gray;
      }
    }
  }
}
