#confirmation-modal.confirmation-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;
  height: 280px;
  width: 600px;
  border-radius: 12px;
  background-color: white;

  .confirmation-modal-decorator {
    height: 13px;
    // background-color: #fa5b5b;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .confirmation-modal-body {
    height: 70%;
  }

  .confirmation-modal-partitioner {
    border-top: 1px solid #eaeaea;
    width: 100%;
    margin: auto;
  }

  .confirmation-modal-footer {
    height: calc(30% - 13px);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: end;
    align-items: center;

    .footer-cancel-btn-container,
    .footer-confirm-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0rem 1.5rem;
      border-radius: 4px;
      margin-right: 1rem;
      cursor: pointer;
    }
    .footer-cancel-btn-container {
      background-color: #f4f5f7;
    }
    .footer-confirm-btn-container {
      color: white;
      // background-color: #fa5b5b;
    }
  }
}

.black-screen {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.162);
  width: 100vw;
  height: 100vh;
  z-index: 11;
}
