@import '../../../DesignSystem/library';

#dialogue-component.dialogue-modal-container {
    min-height: 32rem;
    min-width: 56rem;
    max-width: 60rem;
    background-color: $light;
    border-radius: 1.2rem;
    position: relative;


    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        transform: scale(1.3);
        z-index: 10;
    }

    .diaogue-header {
        font-size: 1.8rem;
        font-weight: 450;
        background-color: $primary50;
        border-radius: 1.2rem 1.2rem 0 0;
        padding: 1.2rem;
        color: $neutral800;
    }


    .dialogue-body {
        margin-bottom: 6rem;
        padding: 1.2rem 2.4rem;
    }

    .footer-btns {
        position: absolute;
        bottom: 1.6rem;
        right: 1.6rem;
    }
}