@import "../../../../DesignSystem/library";

.target-setting-card-component {
  background-color: $light;
  border-radius: 0.8rem;
  width: 30%;
  min-height: 10rem;
  padding: 1.2rem;
  margin-bottom: 1.2rem;
  cursor: pointer;

  .card-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
    position: relative;

    .ts-card-header-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ts-card-popup-container {
      position: absolute;
      top: 2.6rem;
      right: 0rem;
      min-width: 60px;
      font-size: 12px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 0.7rem;
      padding: 0.3rem 0rem;
      border: 1px solid rgb(235, 235, 235);

      .ts-card-popup-edit,
      .ts-card-popup-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        padding: 0rem 1.2rem;

        svg {
          width: 13px;
          height: 13px;
        }
      }
      .ts-card-popup-delete {
        background-color: #fff5f5;
        color: red;
      }
    }
  }

  .details-row {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    gap: 1.2rem;

    .label {
      color: $neutral500;
      font-weight: 400;
    }

    .value {
      color: $neutral1000;
      font-weight: 450;

      .numericals {
        color: $neutral500;
        font-weight: 400;
        font-size: 1.2rem;
      }

      .percentile {
        color: $primary500;
      }
    }
  }

  .action-btns {
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .primary-border-outlined {
      font-size: 1.2rem;
      padding: unset;
      height: unset;
      padding: 0.2rem 0.8rem;
    }

    .target-stat {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;

      svg {
        path {
          fill: $primary100;
        }
      }
    }
  }
}
