.emission-doughnut--labels-container {
  display: flex;
  height: 100%;

  .emission-doughnut-chart-container {
    width: 45%;
  }

  .emisssion-doughnut-labels-container {
    width: 65%;
    height: 300px;
    overflow-y: scroll;
    margin: auto;
    margin-left: 2rem;

    .labels-container-table-header-container {
      display: flex;
      color: #969696;
      margin-bottom: 1rem;
      font-size: 13px;

      .labels-container-table-cell {
        width: 33.33%;
      }

      .labels-header-container-table-cell-column-1 {
        flex: 3;
      }

      .labels-header-container-table-cell-column-2 {
        flex: 2;
      }
      .labels-header-container-table-cell-column-3 {
        flex: 1;
      }
    }

    .labels-container-table-row {
      display: flex;
      margin-bottom: 1.7rem;
      .labels-container-table-cell {
        width: 33.33%;
      }

      .labels-body-container-table-cell-column-1 {
        display: flex;
        justify-content: start;
        align-items: center;
        flex: 3;
        .ebp-label-point {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 0.7rem;
        }
      }

      .labels-body-container-table-cell-column-2 {
        flex: 2;
      }
      .labels-body-container-table-cell-column-3 {
        flex: 1;
      }
    }
  }
}
