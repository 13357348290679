.map {
  path {
    fill: #94c6d2;
    &:focus{
        outline: none;
    }
  }
}
.popup-modal{
    width: 50px;
    height: 100px;
    background-color: #94c6d2;
    z-index: 100;
}