@import "../../../DesignSystem/library";

#SidebarComponentV2.SidebarComponentV2 {
  width: 100%;
  height: 100dvh;

  .se-logo-container {
    width: 100%;

    img {
      height: 4rem;
      width: 12rem;
      margin: 2.4rem 1.6rem;
    }
  }

  .sidebar-item-container {
    width: 100%;
    height: calc(100% - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .rest-container {
      width: 100%;
    }

    .sidebar-content-level-one {
      padding: 0 2rem;
      font-size: 1.4rem;
      font-weight: 400;
      width: 100%;

      .level-one-tile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        margin: 1.6rem 0;
        background-color: $primary500;
        padding: 0.6rem;
        border-radius: 0.4rem;
        color: $light;

        svg {
          path {
            fill: $light;
          }
        }
      }

      .level-one-active {
        background-color: $primary500;
      }

      .level-one-inactive {
        background-color: $lightGrayishBlue;
        color: $dark-inactive;

        svg {
          path {
            fill: $dark-inactive;
          }
        }
      }

      &-two {
        font-size: 1.2rem;
        width: 100%;

        .level-two-tile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1.2rem;
          margin: 1.6rem 0;

          padding: 0.6rem;
          border-radius: 0.4rem;
          color: $dark;

          &-text {
            font-size: 1.4rem;
            font-weight: 400;
          }

          .chevron-icon {
            transform: rotate(90deg);
            height: 1.6rem;
            width: 1.6rem;
            margin-left: auto;
          }

          svg {
            path {
              fill: $dark;
            }
          }

          .scale {
            path {
              fill: $dark;
              stroke: $dark;
            }

            rect {
              fill: $dark;
            }
          }
        }

        .tile-active {
          background-color: $primary50;
        }

        &-three {
          width: 100%;

          .level-three-tile {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.2rem;
            margin: 1.6rem 0;
            // padding: 0.3rem;
            border-radius: 0.4rem;
            margin-left: 2rem;

            svg {
              path {
                fill: $light;
              }
            }
          }

          .active {
            color: $primary500;

            svg {
              path {
                fill: $primary500;
              }
            }
          }
        }
      }
    }
  }

  .progress-tile-container {
    width: 90%;
    text-align: right;

    .progress-tile {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.2rem;
      padding: 0.4rem 0.8rem;
      min-height: 4rem;
      margin: 0.8rem 0;
      width: 100%;
      margin-left: 1.2rem;

      .indicator {
        height: 16px;
        width: 16px;
        background-color: black;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tile-header {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }

    .active {
      background-color: #f7f9fb;
      color: $dark;
      position: relative;

      .indicator {
        svg {
          display: none;
        }
      }

      .active-indicator {
        height: 70%;
        width: 4px;
        background-color: black;
        border-radius: 2px;
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    .completed {
      color: $dark-inactive;
      position: relative;

      .indicator {
        background-color: $dark-inactive;
      }
    }

    .inactive {
      color: $dark;
      position: relative;

      .indicator {
        background-color: unset;

        svg {
          display: none;
        }

        border: 1px solid $neutral200;
      }

      .tile-header {
        color: $dark-inactive;
      }
    }
  }
}
