.emission-breakdown-chart-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .emission-breakdown-chart-header {
    margin-left: 1.2rem;
    margin-top: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
  }

  .emission-breakdown-pie-chart-container {
    width: 60%;
    margin: auto;
  }

  .emission-breakdown-label-container {
    height: 180px;
    width: 100%;
    overflow-y: scroll;
    margin: auto;
    margin-left: 2rem;
    margin-top: 1rem;

    .labels-container-table-header-container {
      display: flex;
      color: #969696;
      margin-bottom: 1rem;
      font-size: 13px;

      .labels-container-table-cell {
        width: 33.33%;
      }

      .labels-header-container-table-cell-column-1 {
        flex: 3;
      }

      .labels-header-container-table-cell-column-2 {
        flex: 2;
      }
      .labels-header-container-table-cell-column-3 {
        flex: 1;
      }
    }

    .labels-container-table-row {
      display: flex;
      margin-bottom: 1.7rem;
      .labels-container-table-cell {
        width: 33.33%;
      }

      .labels-body-container-table-cell-column-1 {
        display: flex;
        justify-content: start;
        align-items: center;
        flex: 3;
        gap: 0.2rem;
        .ebp-label-point {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 0.7rem;
        }
      }

      .labels-body-container-table-cell-column-2 {
        flex: 2;
      }
      .labels-body-container-table-cell-column-3 {
        flex: 1;
      }
    }
  }
}
