@import "../../../../../DesignSystem/library";

.financed-emission-locationDetails-component {
  padding: 2rem;
  overflow-y: scroll;

  .header-filter-row {
    margin-top: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      font-weight: 600;
      color: $neutral600;
      margin-bottom: 1.8rem;

      .header-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;
      }

      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-size: 12px;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
    }

    .filter {
      align-self: center;
      justify-self: end;
    }
  }

  .demographic-container {
    margin: 2rem 0;

    .matrix-chart-text-container {
      padding: 1.67rem 1.5rem;
      .matrix-chart-text-container-value {
        color: $neutral800;
        font-weight: 500;
      }
      .matrix-chart-text-container-category {
        color: #585858;
        font-size: 1.2rem;
      }
    }

    .demographic-tile {
      @include tile-white;
    }

    .country-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;
      flex-direction: column;

      img {
        // min-height: 10rem;
        width: 15rem;
        border-radius: 6px;
        aspect-ratio: 16 / 9;
      }

      .text {
        font-weight: 500;
        font-size: 1.8rem;
      }
    }

    .stats-row {
      column-gap: 3rem;
    }
  }
}
