@import "../../../DesignSystem/library";

.demographics-container-benchmarking {
  margin-top: 2rem;

  .confirmation-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 100%;
    padding: 0rem 3rem;

    .alert-icon-text-container {
      display: flex;
      font-size: 24px;

      .alert-icon-container {
        margin-right: 1rem;
      }
    }

    .confirmation-modal-content-line-1 {
      margin: 1rem 0rem;
    }
  }

  .demographic-tile-benchmarking {
    background-color: $light;
    border-radius: 1.2rem;
    padding: 1.2rem;
    display: flex;
    position: relative;

    .benchmarking-header-btn-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: end;
      align-items: center;
      padding-right: 1rem;

      .save-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        background: #efefef;
        margin-right: 1rem;
        cursor: pointer;
        padding: 0.24rem 1rem;
        font-size: 1.2rem;

        .save-btn-text {
          margin-left: 0.3rem;
        }
      }

      .edit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark-inactive;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 0.2rem 1rem;
        font-size: 1.2rem;
        cursor: pointer;

        div {
          margin-right: 0.5rem;
          margin-top: 0.2rem;
          svg {
            height: 16px;
            width: 15px;
          }
        }
      }

      .delete-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
        cursor: pointer;
        background-color: #fa5b5b;
        border: none;
        margin-left: 1rem;
        color: white;

        div {
          margin-right: 0.5rem;
          margin-top: 0.2rem;
          svg {
            height: 12px;
            width: 12px;
            path {
              fill: white;
            }
          }
        }
      }
    }

    .benchmarking-barchart {
      width: 48%;
      .chart-details-wrapper {
        margin-top: 2rem;
      }
    }

    .benchmarking-barchart-occurence-1 {
      padding-right: 3rem;
      border-right: 1px solid #e6e6e6;
    }
    .benchmarking-barchart-occurence-2 {
      padding-left: 3rem;
    }
  }
}

.save-modal-container {
  .field-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name-input-container {
      height: 42px;
      border: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      margin-top: 0.3rem;
      padding-left: 1rem;
      .bag-icon-palette-container {
        height: 81%;
        width: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.4rem;
        border-radius: 4px;
        cursor: pointer;
        .bag-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
        }
        position: relative;
      }
    }

    .description-input-container {
      height: 94px;
      border: 1px solid #e6e6e6;
      margin-top: 0.3rem;
      border-radius: 6px;
      margin-top: 0.3rem;
      padding-top: 0.5rem;
      padding-left: 0.6rem;
    }
  }

  .field-container-description {
    margin-top: 1.8rem;
  }
}
