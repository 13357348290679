.chart-details-container {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background: #f0f7fa;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .progress-bar {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    height: 10px;

    .progress-bar-filled {
      height: 100%;
      background-color: #14c99e;
      position: relative;

      .progress-bar-filled-legend {
        position: absolute;
        background-color: #14c99e;
        height: 28px;
        width: 28px;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        border-radius: 30px;
        font-family: 500;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .legend-container {
    display: flex;

    .legend-container-legend-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .legend-container-point {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #14c99e;
        margin-right: 1rem;
      }
    }

    .legend-container-legend-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3rem;
      .legend-container-point {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: white;
        margin-right: 1rem;
      }
    }
  }
}
