@import "../../../DesignSystem/library";

.benchmarking-card-container {
  width: 32%;
  height: 300px;
  border-radius: 8px;
  background: #fff;
  padding-left: 1.7rem;
  cursor: pointer;

  .bcc-header {
    display: flex;
    justify-content: space-between;

    .bcc-header-brick {
      border-radius: 4px;
      border: 1px solid rgba(12, 129, 168, 0.2);
      background: rgba(12, 129, 168, 0.06);
      color: $primary500;
      margin-top: 1.2rem;
      padding: 0.3rem 1rem;
    }

    .bcc-header-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0rem 1rem;
      background-color: #9346e8;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;

      svg {
      }
    }
  }

  .benchmarking-name {
    font-weight: bold;
    margin-top: 1.5rem;
    color: $neutral800;
    font-size: 18px;
  }

  .benchmarking-details {
    color: #999fa6;
    margin-top: 0.8rem;
    font-size: 12px;
  }

  .bcc-mapping-container {
    margin-top: 1.5rem;

    .bbc-mapping-key-value-pair {
      display: flex;
      margin-top: 0.5rem;
      color: #808080;
      .bcc-mapping-key {
        display: block;
        width: 150px;
      }

      .bcc-mapping-value {
        color: black;
        margin-left: 1rem;
        width: calc(100% - 150px);
        flex-wrap: wrap;
      }

      .bbc-mapping-value-company-name {
        color: $primary500;
      }
    }
  }
}
