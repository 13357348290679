@import "../../../DesignSystem/library";

#TextInputComponent.text-type-input-container {
  .input-label {
    margin-bottom: 8px;
    font-size: 1.6rem;
    font-weight: 400;
    color: $gray900;
  }

  .input-field {
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    border: 1px solid $neutral400;
    padding: 0 0.8rem;
    

    input {
      min-height: 3rem;
      width: 100%;
      padding: 1.2rem;
      font-size: 1.4rem;
      color: $neutral600;
      border-radius: 0.4rem;
      border: none;
    }
  }

  .theme-light {
    border: 1px solid $neutral400;

    input {
      color: $neutral600;
    }
  }

  .theme-gray {
    border: 1px solid #e6e6e6;
    background: $lightGrayishBlue;

    input {
      color: $neutral600;
      background: $lightGrayishBlue;
      color: $dark;
    }
  }
}
