.colored-rows-container {
  .colored-row {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    .colored-row-text {
      font-weight: 500;
    }
    .colored-row-value {
      font-weight: 500;
    }
  }
}
