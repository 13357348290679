@import "../../../DesignSystem/library";

.partitioner {
  border-bottom: 1px solid #f0f1f2;
}

.tab-dropdown-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .tab-container {
    .asset-class-toggle-switch-btn {
      font-size: 1.3rem !important;
      padding: 0rem;
    }
  }
  .benchmark-dropdown {
    width: 250px;
    font-size: 13px;
  }
}

.checkboxes-container {
  margin: 1rem 0rem;
  .checkboxes-container-title {
    margin-bottom: 1rem;
  }
  .checkboxes-section {
    display: flex;
    div {
      margin-right: 1rem;
    }
    input[type="radio"] {
      accent-color: $primary500;
    }
  }
}

.dropdowns-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  .select-container {
    width: 250px;
    margin-right: 2rem;
    margin-bottom: 2rem;

    .selector-dropdown {
      margin-top: 0.5rem;
    }
  }
}

.time-filter {
  margin-bottom: 2.6rem;
  .time-fitler-title {
    margin-bottom: 0.5rem;
  }

  .time-period-container {
    width: 220px;
    min-height: 35px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: $lightGrayishBlue;
    color: #b7b7b7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;

    .dropdown-container {
      height: 30px !important;
      border-radius: 4px !important;
      svg {
        path {
          fill: #666666;
        }
      }
    }
    .dropdown-body {
      margin-top: 0.2rem;
      max-height: 150px;
      overflow-y: scroll;
    }
  }
}
