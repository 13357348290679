.Tooltip-container-parent {
  position: relative;
  .Tooltip-container {
    position: relative;
    border-radius: 6px;
  }

  .Tooltip-container-arrow {
    height: 10px;
    width: 10px;
    clip-path: polygon(50% 64%, 0 0, 100% 0);
    // margin-left: 50%;
    transform: translate(-50%, 0%);
  }

  .Tooltip-container-arrow-position-center {
    margin-left: 50%;
  }
  .Tooltip-container-arrow-position-right {
    margin-left: calc(100% - 15px);
  }

  .Tooltip-container-arrow-position-left {
    margin-left: 15px;
  }

  .Tooltip-container-arrow-border {
    height: 9px;
    width: 9px;
    background-color: white;
    position: absolute;
    bottom: 5%;
    transform: translate(-50%, 0%);
    clip-path: polygon(50% 64%, 0 0, 100% 0);
  }

  .Tooltip-container-arrow-border-position-center {
    left: 50%;
  }
  .Tooltip-container-arrow-border-position-right {
    left: calc(100% - 15px);
  }
  .Tooltip-container-arrow-border-position-left {
    left: 15px;
  }
}
