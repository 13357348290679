@import '../../../../../DesignSystem/library';

#investeeOnboardingComponent.investee-onboarding-component-container {
    .wrapper-in-data-validation {
        margin: 0;
        height: calc(100dvh - 18rem);
        padding: 2rem;


        .data-validation-body {
            position: relative;

            .header-section {
                padding: 0.4rem 0;
                display: flex;
                justify-content: space-between;

                .section-left,
                .section-right {
                    display: flex;
                    gap: 1.2rem;
                    align-items: center;
                }

                .section-left {
                    .section-headline {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }

                    .section-headline-2 {
                        margin-left: 1.2rem;
                        padding-left: 0.8rem;
                        border-left: 0.4rem solid $primary500;

                        span {
                            color: $primary500;
                            background-color: $primary50;
                            padding: 0.4rem 0.8rem;
                            border-radius: 0.4rem;
                            margin-left: 0.8rem;
                        }
                    }
                }

                .section-right {
                    gap: 1.6rem;

                    .duration-section {
                        background-color: $primary50;
                        padding: 0.4rem 0.8rem;
                        border-radius: 0.4rem;
                        margin-left: 0.8rem;

                        span {
                            color: $primary500;
                        }
                    }

                    .download-icon {
                        transform: rotate(180deg);
                    }
                }

                .primary-border-outlined {
                    height: unset;
                    padding: 0.4rem 0.8rem;
                }

            }

            .header-section-1 {
                display: flex;
                align-items: center;
                gap: 2rem;

                .invite-btn {}

                .search-bar {
                    width: 30%;
                }
            }



            .table-container {
                // height: 100%;
                overflow: scroll;
                margin-bottom: 3rem;
            }

        }
    }

    .footer-action-btns {
        width: 100%;
        min-height: 6rem;
        background-color: $light;
        position: sticky;
        bottom: 1.2rem;
        border-radius: 1.2rem;
        box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .pagination-container {
            .buttons {
                border-radius: 0.4rem;
                background-color: $light;
            }

            .next-button {
                background-color: $primary500;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .previous-button {
                transform: rotate(180deg);
                background-color: $primary500;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .page-button {}

            .page-active {
                border: 1px solid $primary100;
            }

            .pagination-container {}


        }

    }

    .upload-file-investee-modal {
        width: 100%;
        min-height: 100%;

        .sample-content {
            @include container-flex;
            justify-content: flex-end;
            margin-left: auto;
            font-size: 1.2rem;
            color: $primary500;
        }

        .drag-drop-zone {
            border-radius: 1.2rem;
            background-color: $background-gray-light;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E0E0E0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 1.2rem;
            padding: 1.2rem 1.8rem;
            margin: 1.2rem 0;

            .header {
                @include container-flex;
                font-size: 1.8rem;
                color: black;
                font-weight: 500;
                min-height: 12rem;
            }

            .links {
                font-size: 1.4rem;
                font-weight: 500;

                label {
                    color: $primary500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .tile-divider {
            width: 100%;
            display: flex;
            color: $font-primary;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;

            hr {
                width: 45%;
                border: 0.5px solid $neutral50;
                height: 1px;
            }
        }
    }
}