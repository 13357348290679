@import "../../../../../DesignSystem/library";

.datacollection-forms-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.2rem 0;
  position: relative;

  .form-name {
    display: flex;
    gap: 1.2rem;
    align-items: center;
    font-size: 1.8rem;
    color: $neutral600;

    svg {
      transform: scale(0.9);

      path {
        fill: $neutral600;
      }
    }
  }
}

.datacollection-card-container {
  padding: 0 !important;
}

.datacollection-forms-container {
  padding-bottom: 1.2rem;

  .question-section {
    .section-header {
      font-size: 1.6rem;
      font-weight: 400;
      color: $neutral600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .toggle {
        width: 30%;
      }
    }

    .radio-questions-container {
      .header {
        font-size: 1.6rem;
        font-weight: 400;
        color: $neutral600;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .radio-container {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;
        flex-wrap: wrap;
      }
    }

    .questions-container {
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: space-between;
      gap: 2rem;
    }

    .horizontal-row {
      width: 100%;
      margin-bottom: 2.8rem;
      border: 0.5px solid $neutral200;
      margin-top: 2rem;

      &:last-child {
        display: none;
      }
    }
  }

  .save-btn {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
    float: right;
  }
}
