@import "../../../../DesignSystem/library";

#assetClassComponent.asset-class-container {
  .customWrapperClass {
    height: calc(100dvh - $layout-header-height - 2.4rem);
    position: relative;
    padding: 1rem;

    .footer-action-btns {
      float: right;
    }
  }

  overflow-y: scroll;
  height: 100dvh;

  .header {
    font-size: 2rem;
    font-weight: 500;
    // color: $neutral600;
  }

  .add-asset-btn {
    margin-top: 4rem;
    @include container-flex;
  }

  .form-field {
    margin-top: 1.6rem;
    background-color: $bg-light;
    border-radius: 0.8rem;
    padding: 1.2rem 0;
    position: relative;

    .delete-btn,
    .asset-class-edit-btn {
      height: 4rem;
      width: 4rem;
      @include container-flex;
      z-index: 10;

      position: absolute;
      top: 0%;
      right: 0%;

      cursor: pointer;
    }

    .asset-class-edit-btn {
      margin-right: 2.7rem;
      svg {
        path {
          fill: #707c89;
        }
      }
    }

    .form-container {
      padding: 1.2rem;

      display: flex;
      gap: 1.2rem;

      .input-container {
        width: 50%;

        .input-label {
          margin-bottom: 8px;
          font-size: 1.6rem;
          font-weight: 500;
          color: $neutral600;
        }

        .input-field {
          min-height: 3rem;
          width: 100%;
          padding: 0.8rem;
          font-size: 1.4rem;
          border-radius: 0.4rem;
          color: $neutral600;
          background-color: white;
        }
      }
    }
 .sub-asset-container {
      padding: 1.2rem;

      .sub-asset-header {
        margin-bottom: 8px;
        font-size: 1.6rem;
        font-weight: 500;
        color: $neutral600;
      }

      .input-like-field {
        background-color: $light;
        border-radius: 0.8rem;
        padding: 0.8rem;
        display: flex;
        gap: 1.2rem;
        flex-wrap: wrap;

        .data-tile {
          padding: 0.8rem 1.6rem;
          border-radius: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1.2rem;
        }

        .placeholder-with-data {
          background-color: $primary50;
          font-weight: 500;

          .tag {
            font-weight: 400;
          }
        }

        .placeholder-add-item {
          font-size: 600;
          color: $primary400;
          background-color: $primary50;
        }
      }
    }
  }
.assetClassModel{
  
  width: 40vw;
}

}

.confirmation-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;
  padding: 0rem 3rem;

  .alert-icon-text-container {
    display: flex;
    font-size: 24px;

    .alert-icon-container {
      margin-right: 1rem;
    }
  }

  .confirmation-modal-content-line-1 {
    margin: 1rem 0rem;
  }
}
