@import '../../DesignSystem/library';

#landingPageComponent.landing-page-container {
    background-color: $bg-light;
    height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .worldmap {
        position: fixed;
        bottom: 0%;
        top: 10%;
        z-index: 0;
        transform: scale(0.8);
        opacity: 0.2;
    }


    .content-area {
        z-index: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;

        .header {
            font-size: 2.8rem;
            font-weight: 400;
        }

        .cards-container {
            display: flex;
            gap: 4rem;

            .card {
                width: 28rem;
                // background-color: orange;
                background-color: $light;
                min-height: 40rem;
                padding: 1.8rem;
                border-radius: 1.2rem;
                box-shadow: $shadow200;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                transition: all 0.1s linear;

                &:hover {
                    box-shadow: 2px 2px 10px #ccccccaf;
                    transform: translate(0px, -2px);
                    transition: all 0.1s linear;
                }

                .card-caption {
                    text-align: center;

                    .card-name {
                        font-size: 2.6rem;
                        font-weight: 400;
                    }

                    .card-details {
                        font-size: 1.4rem;
                        font-weight: 400;
                    }
                }
            }
        }

    }
}