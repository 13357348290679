.toggleSwitchBodyName {
  width: 100%;
}

.section-container-source {
  background-color: #f6fafb;
  padding: 1.8rem 2.2rem;
}
.question-section {
  .section-container-org {
    padding: 1.8rem 2.2rem;
  }

  .section-container-consumption {
    padding: 1.8rem 2.2rem;
  }
}
