.matrix-chart-container {
  height: 100%;
  padding: 1rem;
  .matrix-header-container {
    font-weight: 500;
    font-size: 1.6rem;
    height: 11%;
  }

  .matrix-body-container {
    height: 79%;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 3px;
    .matrix-body-element-text-container {
    }
  }
  .matrix-footer-container {
    margin-top: 1rem;
    height: 10%;
    display: flex;
    align-items: center;
    .matrix-chart-legend-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;

      .matrix-chart-legend {
        border-radius: 30px;
        height: 16px;
        width: 16px;
        margin-right: 1rem;
      }
    }
  }
}
