$primary50: #ECFAFD;
$primary75: #E2F2F6;
$primary100: #74bed0;
$primary200: #2f94ac;
$primary300: #17839d;
$primary400: #15768d;
$primary500: #0C81A8;
$primary550: #12697e;
$primary600: #116276;
$primary700: #0e4f5e;
$primary800: #0a3b47;
$primary900: #082e37;
$primary950: #05222b;