@import "../../../../DesignSystem/library";

.filtercard-container {
  min-height: 18rem;
  background-color: $light;
  min-width: 20rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    font-size: 20px;
    margin: 1.6rem 0;
    width: 100%;
    border-bottom: 1px solid #00000020;
    padding-bottom: 0.8rem;
    @include container-flex;

    display: flex;
    justify-content: space-between;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    .btn-selectAll {
      color: #0b80a8;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .list {
    padding: 0 1.2rem;
    height: 230px;
    overflow-y: scroll;

    div {
      margin-bottom: 1rem;
    }
  }

  .footer-btns {
    display: flex;
    border-radius: 0 0 0.8rem 0.8rem;
    overflow: hidden;

    .btns {
      width: 100%;
      height: 5rem;
      @include container-flex;
      cursor: pointer;
    }

    .apply {
      background-color: #e2f0f5;
      color: #0b80a8;
    }

    .deselect {
      background-color: $lightGrayishBlue;
      color: #a6a6a6;
    }
  }
}
