@mixin tile-white {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 1.2rem;
}

@mixin customCheckbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  outline: 1px solid #a6a6a6;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 2px;
  position: relative;

  &:checked {
    outline: 1px solid $primary500;

    &::before {
      content: "\2713"; /* Unicode character for checkmark */
      font-size: 12px;
      color: #ffffff;
      background-color: $primary500;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
