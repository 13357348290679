@import '../../../../DesignSystem/library';

#TargetDetailsComponent.target-details-container {
    padding: 2rem;
    width: 100%;
    color: $neutral800;
    overflow-y: scroll;

    .header-row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-name {
            font-size: 2rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.2rem;


            .chevron-icon {
                height: 2.8rem;
                width: 2.8rem;

                path {
                    fill: $neutral800;
                }
            }
        }

        .poweredby-details {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .label {
                color: $neutral400;
            }

            img {
                mix-blend-mode: darken;
                height: 3rem;
            }
        }
    }


    .header-row-2 {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        width: 100%;


        .tile {
            background-color: $light;
            border-radius: 0.8rem;
            padding: 0.8rem 1.6rem;
            min-width: 20rem;
            max-width: 26rem;


            .value {
                font-size: 2rem;
                font-weight: 500;

                .unit {
                    font-size: 1.4rem;
                    margin-left: 0.8rem;
                    color: $neutral400;
                }
            }

            .label {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                margin-top: 1.2rem;
                font-weight: 400;
                color: $neutral600;

                .pointer {
                    height: 1rem;
                    width: 1rem;
                    display: block;
                    background-color: $primary500;
                    border-radius: 0.2rem;
                }
            }
        }
    }

    .graph-container {
        margin-top: 2rem;
        width: 100%;
        background-color: $light;
        height: 70dvh;
        border-radius: 1.2rem;
        padding: 1rem 2rem 7rem 2rem;
    }

    .tile-table {
        margin-top: 2rem;
        width: 100%;
        background-color: $light;
        height: 70dvh;
        border-radius: 1.2rem;
        padding: 1rem;
    }

    .table-data {
        width: 100%;
        background-color: $light;
        border-radius: 1.2rem;
    }

}