@import "DesignSystem/library";

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}


html,
body {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: Poppins;
  font-style: normal;
  font-size: 1.4rem;
}

.App {
  width: 100%;
  height: 100vh;
}