@import '../../../DesignSystem/library';

#UnAutherisedComponent.unautherised-screen {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
        height: 400px;
    }

    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .header1 {
            font-size: 7.2rem;
            font-weight: 500;
            color: $primary500;
        }

        .header2 {
            font-size: 4.2rem;
            font-weight: 400;
        }

        .header3 {
            font-size: 2.2rem;
            font-weight: 400;
            margin: 1.2rem;
        }

        .button {
            background-color: $primary500;
            color: $light;
            font-size: 1.2rem;
            padding: 1.2rem 2.4rem;
            border-radius: 0.4rem;
            cursor: pointer;
        }

    }

}