@import "../../../DesignSystem//library";

.density-chart-graph-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;

  .chart-header {
    margin: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
  }

  .graph-container-flex {
    width: 100%;
    // height: 100%;
    margin-top: 7rem;
    min-height: 10rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    gap: 0.4rem;

    .block-filler {
      min-height: inherit;
      width: 20%;
      @include container-flex;
      color: $light;
      font-weight: 500;
      font-size: 1.2rem;
      cursor: pointer;
      position: relative;
    }

    .tooltip-absolute {
      position: absolute;
      bottom: 100%;
      z-index: 2;
      filter: drop-shadow(0px 12px 42px rgba(24, 39, 75, 0.12))
        drop-shadow(0px 8px 18px rgba(24, 39, 75, 0.12));

      .tooltip-content-container {
        padding: 0.5rem 1rem;
        padding-right: 1.5rem;
        display: flex;
        align-items: center;

        .tooltip-content-container-dot-parent {
          width: 12px;
          margin-right: 0.6rem;
          .tooltip-content-container-dot {
            height: 10px;
            width: 10px;
            border-radius: 50%;
          }
        }

        .tooltip-content-container-text {
          color: #565656;
          margin-right: 1.5rem;
          white-space: nowrap;
        }

        .tooltip-content-container-value {
          color: #17839d;
        }
      }
    }
  }

  .graph-container-flex-vertical {
    width: 100%;
    margin-top: 5rem;
    min-height: 10rem;
    padding: 0.4rem;
    gap: 0.4rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;

    .grid-box {
      height: 200px;
      width: 50%;
      @include container-flex;
      min-height: 2.8rem;
    }
  }

  .legends-collection {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    column-gap: 0.8rem;
    width: 100%;

    .legend-collection-block {
      // grid-column: span 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .indicator {
        height: 10px;
        width: 10px;
        background-color: orange;
        border-radius: 50%;
      }

      .legend {
        // font-size: 1.4rem;
        font-weight: 400;
        text-wrap: nowrap;
      }
    }
  }

  .graph-container-grid {
    width: 100%;
    height: 50rem;

    padding: 0.4rem;
    gap: 0.4rem;
    padding-top: 2rem;

    .chart-container {
      height: inherit;
      // width: inherit;
      padding: 0 1.2rem;

      // display: flex;
      // flex-wrap: wrap;
      // flex-direction: column;
      // justify-content: flex-start;
      // gap: 0.1rem;
      // align-items: center;

      display: grid;
      grid-template-columns: 40rem;

      .block-filler {
        // min-width: 10rem;
        // min-height: 10rem;
        // @include container-flex;
      }
    }
  }
}
