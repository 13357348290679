@import "font-weight";
@import 'Colors/colors';

@mixin heading1($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 40px;
  font-style: normal;
  font-weight: $bold;
  line-height: 44px;
}

@mixin heading2($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 36px;
  font-style: normal;
  font-weight: $bold;
  line-height: 40px;
}

@mixin heading3($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 32px;
  font-style: normal;
  font-weight: $bold;
  line-height: 36px;
}

@mixin heading4($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 24px;
  font-style: normal;
  font-weight: $bold;
  line-height: 28px;
}

@mixin heading5($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 20px;
  font-style: normal;
  font-weight: $bold;
  line-height: 24px;
}

@mixin heading6($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 20px;
  font-style: normal;
  font-weight: $medium;
  line-height: 24px;
}

@mixin body1($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 16px;
  font-style: normal;
  font-weight: $regular;
  line-height: 24px;
}

@mixin body1-med($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 16px;
  font-style: normal;
  font-weight: $medium;
  line-height: 24px;
}

@mixin body2($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 14px;
  font-style: normal;
  font-weight: $regular;
  line-height: 20px;
}

@mixin body2-med($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 14px;
  font-style: normal;
  font-weight: $medium;
  line-height: 20px;
}

@mixin caption($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 12px;
  font-style: normal;
  font-weight: $thin;
  line-height: 18px;
}

@mixin caption-med($color: $dark) {
  // font-family: Poppins,
  color: $color;
  font-size: 12px;
  font-style: normal;
  font-weight: $medium;
  line-height: 18px;
}

@mixin container-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}