@import '../../../../DesignSystem/library';

#InvesteeTargetComponent.investee-target-container {
    padding: 2rem;
    width: 100%;
    color: $neutral800;
    overflow-y: scroll;

    .header-row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-name {
            font-size: 2rem;
            font-weight: 500;
        }

        .poweredby-details {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .label {
                color: $neutral400;
            }

            img {
                mix-blend-mode: darken;
                height: 3rem;
            }
        }
    }

    .header-row-2 {
        display: flex;
    
        align-items: center;
        justify-content: space-between;
        margin: 1.2rem 0;

        .switch {
            width: 55%;
            margin: 0;

            .toggle-switch-button {
                padding: unset;
                font-size: unset;
               

            }
        }
    }

    .content-body {
        .scope-section {
            margin-bottom: 1.2rem;
        }
    
        margin: 0.8rem 0;
    
        .header {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 0.8rem;
        }
    
        .grid-container {
            display: flex;
            flex-wrap: wrap;
            gap: 3%;
        }
    } 
}