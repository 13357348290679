.ebp-date-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ebp-date-selector {
    display: flex;
    align-items: center;
    background-color: #f7f9fb;
    border-radius: 4px;
    padding: 0.6rem;

    .dropdown-container {
      height: 23px !important;
      font-size: small;
      padding: 1.3rem 0.8rem !important;
      border-radius: 4px !important;
    }

    .dropdown-container-months {
      min-width: 11rem !important;
    }

    .dropdown-body {
      font-size: small;
      border-radius: 4px !important;
      margin-top: 0.4rem;
      max-height: 200px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .ebp-partitioner {
      border-right: 1px solid #d4d4d4;
      width: 4px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-right: 8px;
      margin-left: 5px;
    }
    .ebp-right-arrow {
      margin: 0rem 1rem;
    }
  }
}

.ebp-labels-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  // cursor: pointer;

  .ebp-label {
    margin-right: 1rem;
    margin-top: 0.5rem;
    font-size: 0.78vw;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    .ebp-label-point {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-right: 0.7rem;
    }
  }
}
