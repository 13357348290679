@import 'primary';
@import 'secondary';
@import 'success';
@import 'neutral';
@import 'warning';
@import 'error';
@import 'gray';

$dark: #000;
$light: #fff;
$bg-light: #F6F6F6;