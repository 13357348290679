.eac-container {
  width: 100%;
  height: 100%;

  .chart-header {
    margin: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .eac-header-container {
    font-size: 16px;
    font-weight: 500;
    margin: 1.6rem;
  }

  .eac-chart-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    .eac-chart {
      display: flex;
      height: 50%;
      width: 50%;
      padding: 0.3rem;
      .eac-chart-title {
        width: 50px;
        height: 100%;
        div {
          width: fit-content;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;

          h3 {
            font-size: 21px;
            font-weight: 500;
            color: white;
            transform: translateX(-40%) rotate(-90deg);
            white-space: nowrap;
            width: 26rem;
            text-align: center;
            overflow-x: scroll;
          }
        }
      }
      .eac-chart-elements-container {
        width: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .eac-chart-element {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }
}
