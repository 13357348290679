@import "../../../../DesignSystem/library";

.ebc-country-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ebc-country-list-container-title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    font-size: 1.6rem;
    margin-left: 2rem;
    margin-top: 1.2rem;
  }

  .ebc-country-flag-details-container-parent {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 450px;
    overflow-y: scroll;

    .ebc-country-flag-details-container {
      width: 95%;
      height: 55px;
      margin-bottom: 1rem;
      border-radius: 4px;
      background-color: $lightGrayishBlue;
      display: flex;
      align-items: center;

      .ebc-country-flag-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 1rem;

        img {
          width: 100%;
          height: 100%;
          display: block;
          max-width: 100%;
          border-radius: 50%;
        }
      }

      .ebc-country-details-container {
        width: 86%;
        height: 100%;
        padding: 0.8rem 0rem;

        .ebc-country-emission-details-container-parent {
          display: flex;
          height: 60%;

          .ebc-country-emission-details-container {
            display: flex;
            align-items: center;
            font-size: 8px;
            background-color: #fee2e2;
            min-width: 53%;
            border-radius: 4px;
            padding: 0.2rem 0.4rem;
            margin-right: 1rem;
            margin-left: 0.8rem;

            .ebc-country-emission-cloud-icon {
              margin-right: 0.4rem;
              margin-left: 0.2rem;
            }

            .ebc-country-emission-text {
              color: #757575;
              margin-right: 1rem;
            }

            .ebc-country-emission-value {
              span {
                font-weight: 500;
              }
            }
          }

          .ebc-country-investment-details-container {
            display: flex;
            align-items: center;
            font-size: 8px;
            min-width: 41%;
            border-radius: 4px;
            padding: 0.2rem 0.4rem;
            background-color: #fff9f0;

            .ebc-country-investment-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.4rem;
              margin-left: 0.2rem;
            }

            .ebc-country-investment-text {
              margin-right: 1.2rem;
              color: #757575;
            }

            .ebc-country-investment-value {
              font-weight: 500;
            }
          }
        }

        .ebc-country-details-name {
          height: 40%;
          margin-left: 0.8rem;
          font-size: 8px;
          color: #8e8e8e;
          margin-top: 0.2rem;
        }
      }
    }
  }
}
