@import "../../../../../DesignSystem/library";

.filtercard-container {
  min-height: 18rem;
  background-color: $light;
  min-width: 20rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  #filter-category-card.header-container {
    font-size: 20px;
    width: 100%;
    border-bottom: 1px solid #00000020;
    padding-bottom: 0.8rem;
    border-top-right-radius: 0.8rem;
    border-top-left-radius: 0.8rem;

    display: flex;
    padding-left: 0.8rem;
    padding-right: 0.8rem;

    div {
      margin: 1.6rem 0;
    }
  }

  .filter-category-card-body-container {
    padding: 0rem 1.5rem;
    height: 230px;
    overflow-y: scroll;
    color: #4e4e4e;

    .upstream-container,
    .downstream-container {
      .upstream-header-container,
      .downstream-header-container {
        display: flex;
        justify-content: space-between;
        font-size: 18px;

        .upstream-text-container,
        .downstream-text-container {
          display: flex;

          .upstream-text-container-heading,
          .downstream-text-container-heading {
          }

          .upstream-text-container-icon,
          .downstream-text-container-icon {
            cursor: pointer;
            margin-left: 1rem;
          }
        }

        .btn-selectAll {
          color: #0b80a8;
          font-size: 15px;
          cursor: pointer;
        }
      }

      .partitioner {
        border-bottom: 1px solid #00000020;
        width: 100%;
      }

      .upstream-body-container,
      .downstream-body-container {
        box-sizing: border-box;
        // border: 1px solid green;
        // padding: 0 1.2rem;
        padding-top: 1rem;
      }
    }
    .downstream-container {
      margin-top: 1rem;
    }
  }

  .footer-btns {
    display: flex;
    border-radius: 0 0 0.8rem 0.8rem;
    overflow: hidden;

    .btns {
      width: 100%;
      height: 5rem;
      @include container-flex;
      cursor: pointer;
    }

    .apply {
      background-color: #e2f0f5;
      color: #0b80a8;
    }

    .deselect {
      background-color: $lightGrayishBlue;
      color: #a6a6a6;
    }
  }
}
