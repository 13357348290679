@import "../../../../../DesignSystem/library";

.financed-emission-location-component {
  padding: 2rem;
  overflow-y: scroll;

  .header-filter-row {
    margin-top: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      color: $neutral800;
      margin-bottom: 1rem;
      font-size: 22px;
      font-weight: 500;

      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-size: 12px;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
    }

    .filter {
      align-self: center;
      justify-self: end;
    }
  }

  .demographic-container {
    margin: 2rem 0;

    .maplist-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .maplist-container-header {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .demographic-tile {
      @include tile-white;
    }

    .stats-row {
      column-gap: 3rem;
    }
  }
}
