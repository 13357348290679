@import '../../../../DesignSystem/library';


.datacollection-manual-homepage-component {

    padding-top: 2rem;

    .activity-btn {
        @include container-flex;
        justify-content: flex-end;
    }

    .scope-container {
        .header {
            @include container-flex;
            justify-content: flex-start;

            .bold {}

            .light {
                border-left: 2px solid $neutral400;
                padding-left: 2rem;
                color: $neutral400;

            }
        }

        .section-row {

            margin-top: 2.4rem;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 2rem;
            // flex-wrap: wrap;

            .section {
                display: flex;
                justify-content: space-between;
                // gap: 1.2rem;
                align-items: center;
                padding: 0.4rem 1.2rem;
                border-radius: 0.8rem;
                border: 2px solid $neutral100;
                width: 23%;
                min-height: 6rem;
                box-shadow: 0px 0.4px 1.3px 0px rgba(0, 0, 0, 0.03), 2.7px 0px 20.5px 0px rgba(0, 0, 0, 0.06);

                .icon {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}