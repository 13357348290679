@import "../../../DesignSystem/library";

.dashboard-ghgemission-container-component {
  height: $main-contentarea-height;
  padding: 2rem;
  overflow-y: scroll;

  .content-header {
    margin-top: 3rem;
    margin-bottom: -1rem;
    .headline {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      font-weight: 500;
      color: $neutral800;

      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-size: 12px;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
    }
  }

  .stats-filter-container {
    margin-top: 2.8rem;

    .quickstats-container {
      .stats-tile {
        min-height: 18rem;
        background-color: $light;
        min-width: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        border-radius: 0.8rem;

        &-headline {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        &-values {
          font-size: 2.6rem;
          font-weight: 500;

          sub {
            font-weight: 400;
            font-size: 1.4rem;
          }
        }

        &-progress {
          .indicator {
            display: inline-block;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            margin-right: 5px;

            &-success {
              background-color: $success-light;
            }

            &-error {
              background-color: $error-light;
            }
          }

          span {
            font-size: 1.2rem;
            font-weight: 400;
            margin-right: 5px;
          }

          .graph-section {
            padding: 2px 4px;
            border-radius: 4px;

            &-success {
              background-color: #79ac2e0f;
              border: 2px solid #79ac2e1f;

              span {
                color: $success-light;
                margin-right: 5px;
              }
            }

            &-error {
              background-color: #f9545e0f;
              border: 2px solid #f9545e1f;

              span {
                color: $error-light;
                margin-right: 5px;
              }

              svg {
                transform: rotate(180deg);

                path {
                  fill: $error-light;
                }
              }
            }
          }
        }
      }
    }

    .filters {
      .filters-list {
        min-height: 18rem;
        background-color: $light;
        min-width: 20rem;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: relative;

        .header {
          font-size: 2rem;
          margin: 1.6rem 0;
          width: 100%;
          border-bottom: 1px solid #00000020;
          padding-bottom: 0.8rem;
          @include container-flex;
        }

        .list {
          padding: 0 1.2rem;
        }

        .footer-btns {
          // position: absolute;
          bottom: 0;
          display: flex;
          border-radius: 0 0 0.8rem 0.8rem;
          overflow: hidden;

          .btns {
            width: 100%;
            height: 5rem;
            @include container-flex;
            cursor: pointer;
          }

          .apply {
            background-color: #e2f0f5;
            color: #0b80a8;
          }

          .deselect {
            background-color: $lightGrayishBlue;
            color: #a6a6a6;
          }
        }
      }
    }
  }

  .demographics-container {
    margin-top: 2.8rem;

    .demographic-tile {
      @include tile-white;
    }
  }

  .main-chart-container {
    margin-top: 2rem;

    .demographic-tile {
      @include tile-white;
    }

    .chart-header {
      margin: 1.2rem;
      font-weight: 400;
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
    }

    .grid-container-emission {
      background-color: #f5f5f5;
      padding: 0.5rem;
      gap: 0.5rem;
      border-radius: 5px;
    }

    .col-header {
      font-size: 1.4rem;
      font-weight: 400;
    }

    .col-label {
      font-size: 1.6rem;
      font-weight: 600;

      sub {
        font-weight: 400;
        color: #8b8b8b;
      }
    }
  }
}

// this is for the custom bargraph component

#ghg-emissions-component.custom-bargraph-container {
  .chart-header {
    margin: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  .graph-container-table {
    width: 100%;

    thead {
      tr {
        th {
          div {
            padding-left: 1.6rem;
            font-size: 1.6rem;
            font-weight: 400;
            text-align: left;
            min-height: 4rem;
            margin-bottom: 1.2rem;
            @include container-flex;
            justify-content: flex-start;
          }
        }

        .first-col {
          width: 20%;
        }

        .second-col {
          width: 26%;
        }

        .third-col {
          width: 26%;
        }

        .fourth-col {
          width: 26%;
        }
      }
    }

    tbody {
      tr {
        td {
          // width: 24%;

          .cell,
          div {
            padding-left: 1.6rem;
            min-height: 3rem;
            font-size: 1.4rem;
            @include container-flex;
            justify-content: flex-start;
          }

          .progress-bar {
            height: 100%;
            width: 90%;
            padding: 0.4rem;

            .filler {
              border-radius: 3px;
              width: 50%;
              background-color: $primary500;
            }
          }
        }
      }
    }
  }
}
