@import "../../../DesignSystem/library";

.trapezoid-graph-component {
  .chart-header {
    margin: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
  }

  .graph-container {
    display: flex;
    align-items: center;

    .y-axis-label {
      transform: rotate(-90deg);
      display: inline;
      height: 2rem;
      width: 5rem;
      margin-top: 12%;
    }

    .graph {
      z-index: auto;
      position: relative;

      .trapezoid-container {
        z-index: 2;
        position: relative;
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 70% 100%, 30% 100%);

        .trapezoid-section {
          text-align: center;

          .trapezoid-bar {
            height: 3rem;
            min-width: 25rem;
            padding: 0.4rem 0;
          }
        }
      }

      .legends {
        z-index: 1;
        position: absolute;
        left: 60%;
        bottom: 5%;

        .pointer-label {
          transform: translate(0%, 50%);
          color: black;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;

          hr {
            width: 15rem;
            margin: 0;
            padding: 0;
            border: 1px solid $background-gray;
          }

          .pointer-dot {
            height: 10px;
            width: 10px;
            background-color: red;
            margin-right: 1rem;
            border-radius: 50%;
            border: 1px solid $background-gray;
          }

          .label {
            height: 3rem;
            width: max-content;
            z-index: 100;
          }
        }
      }
    }
  }
}
