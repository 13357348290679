$neutral0: #FFF;
$neutral50: #E0E0E0;
$neutral100: #F8F8F8;
$neutral200: #CCCCCC;
$neutral400: #999999;
$neutral500: #808080;
$neutral600: #666666;
$neutral700: #4D4D4D;
$neutral800: #333333;
$neutral900: #1A1A1A;
$neutral1000: #000000;