@import "../../../../../DesignSystem/library";

#investeeInventoryComponent.investee-inventory-container {
  .wrapper-in-data-validation {
    margin: 0;
    height: calc(100dvh - 18rem);
    padding: 2rem;

    .data-validation-body {
      position: relative;

      .header-section-sub {
        height: 100dvh;

        .graph-container {
          background-color: $primary50;
          border-radius: 0.8rem;
          padding: 0.8rem;
          display: flex;
          align-items: center;
          gap: 1.2rem;
          flex-direction: column;

          .graph {
            width: 13rem;
            height: 13rem;
            padding-bottom: 1rem;
            position: relative;

            .graph-numericals {
              position: absolute;
              top: 50%;
              right: 53%;
              transform: translate(50%, 15%);
              font-weight: 500;
            }
          }

          .legends {
            .option {
              display: flex;
              align-items: center;
              gap: 1.2rem;
              cursor: pointer;

              .color-block {
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
              }

              .legend-name {
              }
            }
          }
        }

        .progress-tile-container {
          .progress-tile {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.2rem;
            padding: 0.4rem 0.8rem;
            min-height: 4rem;
            margin: 0.8rem 0;

            .indicator {
              height: 16px;
              width: 16px;
              background-color: black;
              border-radius: 50%;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            .tile-header {
              font-size: 1.2rem;
              font-weight: 500;
            }
          }

          .active {
            background-color: #f7f9fb;
            color: $dark;
            position: relative;

            .indicator {
              svg {
                display: none;
              }
            }

            .active-indicator {
              height: 70%;
              width: 4px;
              background-color: black;
              border-radius: 2px;
              position: absolute;
              right: 0%;
              top: 50%;
              transform: translate(0%, -50%);
            }
          }

          .completed {
            color: $dark-inactive;
            position: relative;

            .indicator {
              background-color: $dark-inactive;
            }
          }

          .inactive {
            color: $dark;
            position: relative;

            .indicator {
              background-color: unset;

              svg {
                display: none;
              }

              border: 1px solid $neutral200;
            }

            .tile-header {
              color: $dark-inactive;
            }
          }
        }
      }

      .table-container {
        // height: 100%;

        margin-bottom: 5rem;

        .header-section {
          .header {
            font-size: 1.6rem;
            font-weight: 500;
          }

          .search-toggle-bar {
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search-bar {
              width: 30%;
            }

            .togglebar {
              width: 30%;
            }
          }
        }

        .table-container-parent {
          overflow: scroll;
        }
      }

      .investee-inventory-submit-tab {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        .data-collection-message-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            font-weight: 500;
            font-size: 2.5rem;
            margin-top: -1rem;
          }

          .go-to-dashboard-btn {
            background-color: $primary500;
            color: white;
            border: none;
            border-radius: 4px;
            padding: 1rem 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
              margin-left: 1rem;
              width: 18;
              path {
                stroke: white;
              }
            }
          }
        }
      }
    }
  }

  .footer-action-btns {
    width: 100%;
    min-height: 6rem;
    background-color: $light;
    position: sticky;
    bottom: 1.2rem;
    border-radius: 1.2rem;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 5;

    .pagination-container {
      .buttons {
        border-radius: 0.4rem;
        background-color: $light;
      }

      .next-button {
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .previous-button {
        transform: rotate(180deg);
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .page-button {
      }

      .page-active {
        border: 1px solid $primary100;
      }

      .pagination-container {
      }
    }
  }

  .upload-file-investee-modal {
    width: 100%;
    min-height: 100%;

    .sample-content {
      @include container-flex;
      justify-content: flex-end;
      margin-left: auto;
      font-size: 1.2rem;
      color: $primary500;
    }

    .drag-drop-zone {
      border-radius: 1.2rem;
      background-color: $background-gray-light;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E0E0E0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 1.2rem;
      padding: 1.2rem 1.8rem;
      margin: 1.2rem 0;

      .header {
        @include container-flex;
        justify-content: flex-start;
        font-size: 1.8rem;
        color: black;
        font-weight: 500;
      }

      hr {
        border: 1px solid $light;
      }

      .sub-header {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
      }

      .links {
        font-size: 1.4rem;
        font-weight: 500;

        label {
          color: $primary500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .tile-divider {
      width: 100%;
      display: flex;
      color: $font-primary;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;

      hr {
        width: 45%;
        border: 0.5px solid $neutral50;
        height: 1px;
      }
    }
  }
}
