@import "../../../DesignSystem/library";

.dashboard-overview-container-component {
  height: $main-contentarea-height;
  padding: 2rem;
  overflow-y: scroll;

  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headline {
      font-size: 25px;
      font-weight: 500;
      color: $neutral800;
    }

    .date-location-selector-container {
      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
      display: flex;

      .date-selector {
        height: 100%;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 0.6rem;

        .date-selector-dropdown-body {
          background-color: #f7f9fb !important;
        }
      }

      .location-selector {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        margin-left: 1rem;
        padding: 0.6rem;
        border-radius: 4px;

        .dropdown-container {
          height: 32px !important;
          border-radius: 4px !important;
          box-shadow: none !important;
          position: relative;
        }

        .dropdown-body {
          margin-top: 1rem;
          margin-right: calc(-100% - 4.7rem) !important;
          border-radius: 4px !important;
          max-height: 350px;
          overflow-y: scroll;
          width: 230px !important;
        }

        .date-selector-datePartitioner {
          border-right: 1px solid #d4d4d4;
          width: 4px;
          height: 23px;
          display: flex;
          justify-content: center;
          align-content: center;
          margin-right: 8px;
          margin-left: 5px;
        }

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .quickstats-container {
    margin-top: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stats-tile {
      min-height: 18rem;
      background-color: $light;
      width: 24%;
      min-width: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.2rem;
      border-radius: 0.8rem;

      &-headline {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      &-values {
        font-size: 2.6rem;
        font-weight: 500;

        sub {
          font-weight: 400;
          font-size: 1.4rem;
        }
      }

      &-progress {
        .indicator {
          display: inline-block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          margin-right: 5px;

          &-success {
            background-color: $success-light;
          }

          &-error {
            background-color: $error-light;
          }
        }

        span {
          font-size: 1.2rem;
          font-weight: 400;
          margin-right: 5px;
        }

        .graph-section {
          padding: 2px 4px;
          border-radius: 4px;

          &-success {
            background-color: #79ac2e0f;
            border: 2px solid #79ac2e1f;

            span {
              color: $success-light;
              margin-right: 5px;
            }
          }

          &-error {
            background-color: #f9545e0f;
            border: 2px solid #f9545e1f;

            span {
              color: $error-light;
              margin-right: 5px;
            }

            svg {
              transform: rotate(180deg);

              path {
                fill: $error-light;
              }
            }
          }
        }
      }
    }
  }

  .demographics-container {
    margin-top: 1rem;

    .maplist-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .maplist-container-header {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .demographic-tile {
      background-color: $light;
      border-radius: 1.2rem;
      padding: 1.2rem;

      .chart-header {
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: -1.4rem;

          .dropdown {
            .dropdown-body {
            }
          }

          .date-selector {
            // width: 50%;
            height: 38px;
            background-color: #f7f9fb;
            border-radius: 4px;
            padding: 0.6rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.marker-filledCloudIcon-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .marker-circle {
    background-color: rgba(250, 91, 91, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;

    animation: animateCircle 2s ease-in-out infinite;
    @keyframes animateCircle {
      // 0% {
      //   opacity: 0;
      // }
      // 75% {
      //   opacity: 100;
      // }
      // 100% {
      //   opacity: 0;
      // }

      0% {
        transform: scale(0);
      }
      75% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }

  .marker-circle-green {
    background-color: rgba(121, 172, 46, 0.2);
  }

  .marker-circle-yellow {
    background-color: rgba(255, 153, 0, 0.2);
  }

  .filledcloud-green {
    circle {
      fill: rgb(121, 172, 46);
    }
  }
  .filledcloud-yellow {
    circle {
      fill: rgb(255, 153, 0);
    }
  }
}
