$error50: #FFE5EE;
$error100: #FFCCCC;
$error200: #FF9999;
$error300: #FF6666;
$error400: #FF3333;
$error500: #FF0000;
$error600: #CC0000;
$error700: #990000;
$error800: #660000;
$error900: #330000;
$error950: #1A0000;


$error-light: #F9545E;