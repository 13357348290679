@import "../../../DesignSystem/library";

#barGraph.custom-multi-bargraph-component {
  height: 300px;
  overflow-y: scroll;

  .chart-header {
    margin: 1.2rem;
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  .graph-container-table {
    width: 100%;

    thead {
      tr {
        th {
          .header {
            padding-left: 1.6rem;
            font-size: 1.6rem;
            font-weight: 400;
            text-align: left;
          }
          .header-container {
            display: flex;
            align-items: center;
            justify-content: start;
            padding-top: 1.6rem;
            &-dot {
              width: 15px;
              height: 15px;
              border-radius: 50%;
            }
          }
        }

        .table-header:not(.table-header:first-child) {
          // width: 10rem;
          // width: 25%;
        }
      }
    }

    tbody {
      tr {
        background-color: $lightGrayishBlue;
        position: relative;

        td {
          cursor: pointer;
          &:first-child {
            max-width: 55%;
            min-width: 15rem;
          }

          &:not(:first-child) {
            min-width: 30%;
            min-width: 20rem;
          }
          .cell,
          div {
            padding-left: 1.6rem;
            min-height: 3rem;
            font-size: 1.4rem;
            @include container-flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
          }

          .progress-bar {
            height: 100%;
            width: 90%;
            padding: 0.4rem;

            .filler {
              border-radius: 3px;
              width: 50%;
            }
          }
        }

        .tooltip-absolute {
          position: absolute;
          bottom: 3.5rem;

          .tooltip-container {
            padding: 1rem;

            .tooltip-header {
              color: #17839d;
              font-size: 12px;
            }

            .tooltip-body {
              span:first-child {
                font-size: 12px;
                color: #565656;
              }
              span:nth-child(2) {
                color: #404040;
                font-size: 12px;
              }
              span:nth-child(3) {
                color: $neutral500;
                font-size: 9px;
                margin-left: 0.3rem;
              }
            }
          }
        }
      }
    }
  }
}
