@import "../../../../DesignSystem/library";

.maplist-container-map-section {
  width: 73%;
  margin-bottom: -10rem;
  margin-right: 2rem;
  .maplist-container-map {
    margin: auto;
    width: 92%;
  }
}

.maplist-container-list-section {
  min-width: 220px;
  width: 25%;
  border-radius: 10px;
  background: $lightGrayishBlue;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1rem;

  .maplist-container-list-section-title {
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }

  .maplist-container-list-section-body-parent {
    cursor: pointer;
    width: 90%;
    padding: 0.6rem 0rem;
    max-height: 535px;
    overflow-y: scroll;

    .maplist-container-list-section-body {
      display: flex;
      width: 100%;
      margin-bottom: 0.7rem;
      border-radius: 6px;
      background: #fff;
      padding: 0.6rem 0.8rem;
      min-width: 200px;

      .listitem-image-container {
        height: 100%;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 4rem; /* Maintain the aspect ratio of the image */
          display: block; /* Remove extra space under the image */
          max-width: 100%;
          border-radius: 50%;
        }
      }

      .listitem-details-container {
        font-size: 12px;
        width: 80%;
        margin-left: 1rem;
        .details-container-emission {
          border-radius: 4px;
          border: 0.6px solid rgba(250, 91, 91, 0.12);
          background: rgba(250, 91, 91, 0.06);
          display: flex;
          justify-content: space-between;
          padding: 0 1rem;

          .details-container-emissionandicon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #757575;

            .details-container-emission-cloud-icon {
              margin-right: 0.4rem;
              margin-top: 0.2rem;

              .cloud-icon-red {
                path {
                  fill: #fa5b5b;
                }
              }

              .cloud-icon-orange {
                path {
                  fill: #f90;
                }
              }

              .cloud-icon-green {
                path {
                  fill: #79ac2e;
                }
              }
            }
          }

          .details-container-emission-value {
            font-weight: 500;
            color: black;
          }
        }

        .details-container-country {
          margin-top: 0.3rem;
          color: #8e8e8e;
        }
      }
    }
  }
}
