@import '../../../../DesignSystem//library';


#dataValidationComponent.data-validation-container {
    margin: 2.4rem;
    color: $font-primary;

    .primary-no-outline {
        font-size: 1.4rem;

        .download-icon {
            @include container-flex;
        }

        svg {
            height: 1.8rem;
            width: 1.8rem;
        }
    }
}