@import "../../../DesignSystem/library";

.maincontent-wrapper-component {
  margin: 1.4rem;

  background-color: $light;
  height: 100dvh;
  border-radius: 1.2rem;
  overflow-x: hidden;
}
