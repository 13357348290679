.demographic-tile {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -1.4rem;

    .dropdown {
      .dropdown-body {
      }
    }

    .date-selector {
      // width: 50%;
      height: 38px;
      background-color: #f7f9fb;
      border-radius: 4px;
      padding: 0.6rem;
      font-weight: normal;
    }
  }
}
