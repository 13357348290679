@import '../../DesignSystem/library';

.layout-component {
    display: flex;

    .icon-wrapper {
        cursor: pointer;
    }

    .sidebar-container {
        min-width: $layout-sidebar-width;
        height: 100dvh;
    }

    .header-mainview-container {

        .header-container {
            background-color: $light;
            height: $layout-header-height;
            min-height: $layout-header-height;
        }

        display: flex;
        flex-direction: column;
        // flex: 1;
        flex-grow: 1;
        overflow: hidden;
        height: 100vh;
        background-color: $background-gray;

    }
}