@import "../../../DesignSystem//library";

.benchmarking-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2.7rem;
  padding-top: 2rem;

  .benchmarking-header-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $neutral800;
    margin-bottom: 1rem;
    font-size: 22px;
    font-weight: 500;
    margin: auto 0;

    .benchmarking-header-title-back-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
      }
    }

    .benchmarking-header-title {
      margin-left: 1.5rem;
    }
  }
  .benchmarking-add-graph-btn-container {
    .add-graph-btn {
      background-color: $primary500;
      color: white;
      padding: 0.8rem 1.5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      font-size: 14px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin-right: 0.7rem;

        svg {
          height: 11px;
          width: 11px;
          path {
            stroke: white;
          }
        }
      }
    }
  }
}
