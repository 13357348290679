@import "../../../../../DesignSystem/library";

#investeeListComponent.investee-component-container {
  .wrapper-in-data-validation {
    margin: 0;
    height: calc(100dvh - 18rem);
    padding: 2rem;

    .data-validation-body {
      position: relative;

      .header {
        position: relative;

        .filter-modal {
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          // background-color: rgba(255, 166, 0, 0.463);
          background-color: $light;
          padding: 0.8rem;
          border-radius: 0.8rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 1.2rem;
          z-index: 1;
          .dropDown-assetInventoryClass{
         
            min-height: 15rem;
            max-height: 18rem;
            overflow-y: scroll;

          }

          .dropdown-close-btn {
            position: absolute;
            bottom: 1%;
            right: 1%;
          }
        }

        .header-section {
          padding: 0.4rem 0;
          display: flex;
          justify-content: space-between;

          .section-left,
          .section-right {
            display: flex;
            gap: 1.2rem;
            align-items: center;
          }

          .section-left {
            .section-headline {
              font-size: 1.4rem;
              font-weight: 500;
            }

            .section-headline-2 {
              margin-left: 1.2rem;
              padding-left: 0.8rem;
              border-left: 0.4rem solid $primary500;

              span {
                color: $primary500;
                background-color: $primary50;
                padding: 0.4rem 0.8rem;
                border-radius: 0.4rem;
                margin-left: 0.8rem;
              }
            }
          }

          .section-right {
            gap: 1.6rem;

            .duration-section {
              background-color: $primary50;
              padding: 0.4rem 0.8rem;
              border-radius: 0.4rem;
              margin-left: 0.8rem;

              span {
                color: $primary500;
              }
            }

            .plus-icon {
              path {
                stroke: $light;
              }
            }
          }

          .primary-border-outlined {
            height: unset;
            padding: 0.4rem 0.8rem;
          }
        }

        .header-section-sub {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 1.2rem;

          .stats {
            display: flex;
            justify-content: flex-start;
            gap: 1.2rem;

            .stat-tile {
              padding: 1.2rem 1.8rem;
              border-radius: 1.2rem;

              .icon-container {
                height: 2.4rem;
                width: 2.4rem;
                background-color: $light;
                @include container-flex;
                border-radius: 0.4rem;
              }

              .content {
                display: flex;
                align-items: center;
                gap: 0.8rem;
              }
            }

            .stats-yellow {
              border: 1px solid #fdefc6;
              background: #fef7e3;
            }

            .stats-green {
              border: 1px solid #2dd03d29;
              background: #e6f9e8;
            }

            .stats-blue {
              border: 1px solid rgba(12, 140, 233, 0.16);
              background: rgba(12, 140, 233, 0.12);
            }
          }

          .search-inputs {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .filter-btn {
              border: 1px solid $neutral400;
              font-size: 1.4rem;
              color: $neutral500;
            }
          }
        }

        ƒˇ .filter-btn {
          border: 1px solid $neutral400;
          font-size: 1.4rem;
          color: $neutral500;
        }
      }

      .table-container {
        // height: 100%;
        overflow: scroll;
        margin-bottom: 3rem;
      }
    }
  }

  .footer-action-btns {
    width: 100%;
    min-height: 6rem;
    background-color: $light;
    position: sticky;
    bottom: 1.2rem;
    border-radius: 1.2rem;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .container {
      .buttons {
        border-radius: 0.4rem;
        background-color: $light;
      }

      .next-button {
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .previous-button {
        transform: rotate(180deg);
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .page-active {
        border: 1px solid $primary100;
      }
    }
  }

  .upload-file-investee-modal {
    width: 100%;
    min-height: 100%;

    .sample-content {
      @include container-flex;
      justify-content: flex-end;
      margin-left: auto;
      font-size: 1.2rem;
      color: $primary500;
    }

    .drag-drop-zone {
      border-radius: 1.2rem;
      background-color: $background-gray-light;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E0E0E0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 1.8rem;
      padding: 1.2rem 1.8rem;
      margin: 1.2rem 0;
      min-height: 16rem;

      .header {
        @include container-flex;
        justify-content: flex-start;
        font-size: 1.8rem;
        // color: black;
        font-weight: 500;
      }

      hr {
        border: 1px solid $light;
      }

      .sub-header {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
      }

      .links {
        font-size: 1.4rem;
        font-weight: 500;

        label {
          color: $primary500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .tile-divider {
      width: 100%;
      display: flex;
      color: $font-primary;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;

      hr {
        width: 45%;
        border: 0.5px solid $neutral50;
        height: 1px;
      }
    }
  }
}
