@import "../../../DesignSystem/library";

#SidebarComponent.sidebar-component {
  width: 100%;
  height: 100dvh;
  padding: 0 1rem;

  .se-logo-container {
    cursor: pointer;
    width: 100%;
    img {
      height: 4rem;
      width: 12rem;
      margin: 1rem;
    }
  }

  .sidebar-main-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 73%;

    .sidebar-header-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin: 1.6rem 0;
      margin-bottom: 2.5rem;
      background-color: $primary500;
      padding: 0.6rem;
      padding-left: 1rem;
      border-radius: 0.4rem;
      color: $light;

      svg {
        path {
          fill: $light;
        }
      }
    }

    .sidebar-item {
      display: flex;
      cursor: pointer;

      .sidebar-item-tile-container {
        display: flex;
        width: 100%;
        gap: 1rem;

        padding: 0.6rem;
        padding-left: 1rem;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 1rem;

        color: $dark-inactive;

        svg {
          path {
            fill: $dark-inactive;
          }
        }
        .sidebar-parent-chevron-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          width: 2rem;
          margin-left: -0.3rem;

          .sidebar-parent-chevron-icon-active,
          .sidebar-parent-chevron-icon-inactive {
            rotate: 90deg;
            height: 1.4rem;
            width: 1.4rem;
            margin: auto;
          }

          .sidebar-parent-chevron-icon-active {
            rotate: -90deg;
          }
        }
      }

      .sidebar-tile-active {
        background-color: $primary50;
        color: black;
        svg {
          path {
            fill: black;
          }
        }
      }
    }

    .sidebar-tile-child-container {
      padding-left: 4rem;
      margin-top: -0.2rem;

      .sidebar-child-tile-active,
      .sidebar-child-tile-inactive {
        font-size: 12px;
        gap: 0.4rem;
        width: 100%;
        cursor: pointer;
        margin-bottom: 1rem;

        .sidebar-child-chevron-icon {
          height: 1.2rem;
          width: 1.2rem;
          margin: auto;
          margin-right: 0.4rem;
          padding-top: 0.4rem;
        }
      }

      .sidebar-child-tile-active {
        color: $primary500;
      }
    }
  }
  .sidebar-footer-container {
    .sidebar-footer-switch-tab {
      div {
        cursor: pointer;
        background-color: $lightGrayishBlue;
        color: $dark-inactive;
        display: flex;
        width: 100%;
        gap: 1rem;
        padding: 0.6rem;
        padding-left: 1rem;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 1.3rem;
      }
    }

    .sidebar-footer-partitioner {
      border-bottom: 1px solid #e6e6e6;
    }

    .sidebar-footer-extra-btn-container {
      margin-top: 1.5rem;
      div {
        width: 100%;
        cursor: pointer;
        display: flex;
        gap: 1rem;
        padding: 0.6rem;
        padding-left: 1rem;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 0.5rem;
      }

      .sidebar-footer-help-center-btn-container {
        padding-left: 0.86rem;
      }
    }
  }
}
