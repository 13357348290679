.d-none {
  display: none !important;
}

// display flex

.d-flex {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

// display grid settings
.d-grid-c6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.d-grid-c8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
}

.d-grid-c10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
}

.d-grid-center {
  justify-self: center;
  align-self: center;
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-8 {
  grid-column: span 8;
}

.row-1 {
  grid-row: span 1;
}

.row-2 {
  grid-row: span 2;
}

.row-3 {
  grid-row: span 3;
}

.row-4 {
  grid-row: span 4;
}

.row-5 {
  grid-row: span 5;
}

.row-6 {
  grid-row: span 6;
}

.col-end {
  grid-row: -1 / 1;
}

//  display flexbox properties
.d-flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

// position related settings

.p-rel {
  position: relative;
}

// overflow related stylings
.overflow-x-hidden {
  overflow-x: hidden;
}

// make scrollable and hide scrollbar
.hide-scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}