.partner-integration-component {}

@import '../../../../DesignSystem/library';


#partnerIntegrationComponent.partner-integration-container {
    padding-top: 2rem;

    .scope-container {
        .header {
            @include container-flex;
            justify-content: flex-start;

            .bold {}

            .light {
                border-left: 2px solid $neutral400;
                padding-left: 2rem;
                color: $neutral400;

            }
        }

        .section-row {

            margin-top: 2.4rem;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 2rem;
            // flex-wrap: wrap;

            .section {

                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 1.2rem;
                align-items: center;

                padding: 1.2rem;
                border-radius: 0.8rem;
                border: 2px solid $neutral100;
                width: 20rem;
                min-height: 20rem;
                box-shadow: 0px 0.4px 1.3px 0px rgba(0, 0, 0, 0.03), 2.7px 0px 20.5px 0px rgba(0, 0, 0, 0.06);


                .left {
                    width: 100%;
                    background-color: $primary50;
                    text-align: center;
                    padding: 0.8rem;
                    // @include container-flex;

                    img {
                        height: 6rem;
                    }
                }

                .icon {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.4rem;
                }
            }
        }
    }
}