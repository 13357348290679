@import "../../../DesignSystem/library";

.action-button {
  display: flex;
  @include body2;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    background-color: #b7d9e1;
  }
}

.menu-button {
  display: flex;
  background-color: $light;
  border-radius: 8px;
  border: 1px solid $neutral100;
  @include body2-med;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  height: 52px;
  width: 317px;
  padding: 0 12px;
}

.primary-button {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  gap: 1rem;
  align-items: center;
  background-color: $primary500;
  border-radius: 4px;
  color: $light;
  border: 0;
  box-shadow: none;
  
}

.primary-no-outline {
  display: flex;
  gap: 0.8rem;
  padding: 1rem 0;
  @include body1;
  color: $primary500;
  background: none;
  border: 0;
}

.light-border-outlined {
  display: flex;
  gap: 18px;
  padding: 8px 24px;
  @include body1;
  color: $dark;
  border-radius: 6px;
  border: 1px solid #ececeb;
  background: $light;
  height: 42px;
}

.primary-border-outlined {
  display: flex;
  gap: 18px;
  padding: 8px 24px;
  @include body1;
  color: $dark;
  border-radius: 6px;
  border: 1px solid $primary500;
  background: $primary50;
  height: 42px;
  color: $primary500;
}

.search-button,
.filter-button {
  display: flex;
  gap: 18px;
  padding: 8px 24px;
  @include body1;
  color: $neutral500;
  border-radius: 6px;
  border: 1px solid #ececeb;
  background: $light;
  height: 42px;
}

.action-btn-reusable:hover {
  opacity: 0.9;
}