@import "../../../../DesignSystem/library";

.portfolio-analysys-overview-component {
  // height: $main-contentarea-height;
  padding: 2rem;
  overflow-y: scroll;

  .header {
    color: $neutral800;
    margin-bottom: 1rem;
    font-size: 22px;
    font-weight: 500;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .poweredby-details {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      font-size: 12px;

      .label {
        color: $neutral400;
      }

      img {
        mix-blend-mode: darken;
        height: 3rem;
      }
    }
  }

  .header-1 {
    font-size: 16px;
    font-weight: 400;
    color: $neutral600;
    margin-bottom: 1.4rem;
  }

  .demographic-container {
    margin: 2rem 0;

    .demographic-tile {
      @include tile-white;

      .asset-class-toggle-switch-btn {
        font-size: 1.3rem !important;
        padding: 0rem;
        cursor: pointer;
      }

      .asset-class-toggle-switch-btn-small {
        font-size: 0.9rem !important;
        padding: 0rem;
      }

      .toggle-switch-container {
        .toggle-switch-component {
          button {
            font-size: 1.2rem;
          }
        }
      }
    }

    .tile-header {
      font-size: 1.5rem;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      .chart-seemore-btn-container {
        margin-right: -1rem;
        button {
          color: #17839d;
          font-size: 1rem;
          border-radius: 4px;
          border: 1px solid #e4f4f8;
          background: #fbfdfe;
          padding: 6px 12px;
          cursor: pointer;
        }
      }
    }
  }
}
