@import "../../../../DesignSystem/library";

.financed-emission-overview-component {
  // height: $main-contentarea-height;
  padding: 2rem;
  overflow-y: scroll;

  .header {
    color: $neutral800;
    margin-bottom: 1rem;
    font-size: 22px;
    font-weight: 500;

    display: flex;
    justify-content: space-between;

    .poweredby-details {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      font-size: 12px;

      .label {
        color: $neutral400;
      }

      img {
        mix-blend-mode: darken;
        height: 3rem;
      }
    }
  }

  .demographic-container {
    margin: 2rem 0;

    .matrix-chart-text-container {
      padding: 1.67rem 1.5rem;
      .matrix-chart-text-container-value {
        color: $neutral800;
        font-weight: 500;
      }
      .matrix-chart-text-container-category {
        color: #585858;
        font-size: 1.2rem;
      }
    }

    .demographic-tile {
      @include tile-white;

      .emission-region-map-graph {
        height: 91%;

        .emission-region-map-marker-container {
          border: 1px solid #9dca5c;
          display: flex;
          align-items: center;
          border-radius: 50px;
          border: 1px solid #e7e8eb;
          background: #fff;
          min-width: 54px;
          padding: 0.3rem 0.5rem;
          position: relative;
          margin-top: 1rem;

          .emission-region-marker-circle {
            width: 40px;
            height: 40px;
            position: absolute;
            top: -1rem;
            left: -1rem;
            border-radius: 50%;
            z-index: -1;
          }

          .marker-circle-green {
            background-color: #ebf4de;
          }

          .marker-circle-yellow {
            background-color: #fef5df;
          }

          .marker-circle-red {
            background-color: #fedede;
          }

          .marker-point-green {
            background-color: #9dca5c;
          }

          .marker-point-yellow {
            background-color: #face5e;
          }

          .marker-point-red {
            background-color: #fa5b5b;
          }

          .emission-region-marker-point {
            height: 12px;
            width: 12px;
            border-radius: 50%;
          }
          .emission-region-marker-text {
            font-size: 10px;
            margin-left: 0.5rem;
            color: #565656;
            font-weight: 500;
            width: 75%;
            overflow-wrap: break-word;
          }
        }
      }
      .emission-region-footer-container {
        display: flex;
        align-items: start;
        height: 9%;
        padding: 0rem 1rem;
        .emission-region-legend-container {
          display: flex;
          align-items: center;
          margin-right: 2rem;

          .emission-region-legend {
            border-radius: 50%;
            height: 16px;
            width: 16px;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
