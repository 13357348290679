@import "../../../DesignSystem/library";

.table-container-parent {
  height: 63vh;
  overflow-y: scroll;
}

#tableComponent.table-container {
  min-width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;
  // min-height: 10rem;
  border: none;

  thead {
    background-color: $primary75;
    color: $neutral800;
    height: 5rem;
    // position: sticky;
    top: 0;

    th {
      min-height: 4rem;
      text-align: left;
      padding: 0.4rem 0.8rem;
      min-width: 5rem;
      font-weight: 500;
      max-width: max-content;
    }
  }

  tbody {
    tr {
      height: 6rem;
      border-bottom: 1px solid $background-gray;

      &:hover {
        background-color: $background-gray;
      }
    }

    td {
      .menu-container {
        height: 6rem;
        // max-width: 15rem;
        padding: 0.4rem 0.8rem;
        text-align: left;
        font-weight: 400;
        color: black;
        width: 100%;
        // z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
cursor: pointer;
        position: relative;
        @include container-flex;

        .icon {
          transform: rotate(90deg);
        }

        .popup-container {
          z-index: 2;
          position: absolute;
          top: 5%;
          right: 40%;
          transform: translate(-20%, 0%);
          background-color: $light;
          min-height: 5rem;
          min-width: 11rem;
          padding: 0.8rem;
          border-radius: 0.8rem;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);

          display: flex;
          align-items: baseline;
          gap: 0.4rem;
          justify-content: center;
          flex-direction: column;

          .edit,
          .delete {
            span {
              display: flex;
              align-items: center;
              gap: 1.2rem;
              width: 100%;
              padding: 0 0.8rem;
            }
          }

          .delete {
            display: flex;
            align-items: center;
            gap: 1.2rem;
            width: 100%;
            padding: 0 0.8rem;
            background-color: $error50;
            color: $error300;
            border-radius: 0.4rem;
          }
        }
      }

      .data-cell {
        height: 6rem;
        // max-width: 26rem;
        padding: 0.8rem;
        text-align: left;
        font-weight: 400;
        color: black;
        white-space: nowrap;
        width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 2;

        // display: flex;
        // align-items: center;
        // justify-content: flex-start;

        .edit-input {
          width: 100%;
          font-size: inherit;
          color: $neutral500;
          font-size: 1.4rem;
          height: 100%;
        }
      }

      .border-yellow {
        background-color: #f59e0b0f;
        border-top: 2px solid #f59e0b;
      }

      .border-blue {
        background-color: #3b83f60f;
        border-top: 2px solid #3b82f6;
      }

      .border-red {
        background-color: #fa5b5b0f;
        border-top: 2px solid #fa5b5b;
      }

      .border-green {
        background-color: #14b8a60f;
        border-top: 2px solid #14b8a6;
      }

      .background-blue {
        color: #3b82f6;
        background-color: #d3eafb;
      }

      .background-red {
        color: #fa5b5b;
        background-color: #fceaea;
      }

      .background-green {
        color: #22ab30;
        background-color: #e6f9e8;
      }

      .data-pending {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $error50;
        color: $error400;
        height: 3rem;
        width: max-content;
        border-radius: 0.4rem;
      }

      .data-complete {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $success50;
        color: $success600;
        height: 3rem;
        width: max-content;
        border-radius: 0.4rem;
      }
    }
  }
}
