@import "../../../DesignSystem/library";

.list-data {
  .header {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 1.2rem;
  }

  .data-table {
    &-header {
      @include container-flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 1.2rem 1rem;
      margin-bottom: 1.2rem;
      background-color: $primary50;
      font-size: 1.4rem;

      .cell {
        text-align: start;
      }
    }

    &-body {
      padding: 0rem 1rem;
      height: 310px;
      overflow-y: scroll;

      .table-row {
        @include container-flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin: 0.8rem 0.4rem;

        .cell {
          //   @include container-flex;

          img {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
          }
        }

        .main-cell {
          justify-content: flex-start;
          flex-wrap: nowrap;
        }
      }

      .table-row-interact {
        padding: 0.4rem 0;
        border-bottom: 1px solid $neutral200;
        margin: 1.2rem 0.4rem;

        &:last-child {
          border: unset;
        }

        &:hover {
          background-color: $neutral100;
        }
      }

      .list-chart-row-divider {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
