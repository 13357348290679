@import '../../../DesignSystem/library';

#InvesteeDashboard.investee-dashboard-container {
    padding: 2.4rem 2rem;
    color: $neutral800;

    .header-label {
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.2rem;
    }

    .content-wrapper-classname {
        margin: 1.2rem 0;
        height: 85dvh;
        position: relative;
        padding: 0;
    }

    .main-content-wrapper {
        padding: 1.6rem;

        .filter-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1.2rem;
            padding: 0 1.2rem;
            margin-bottom: 2rem;

            #dropdown.dropdown-menu-component {
                width: 22%;
            }

            .header-label {
                width: max-content;
            }

            .section-right {
                display: flex;
                justify-content: flex-end;
                gap: 4rem;
                align-items: center;
                width: 80%;

                .input-label {
                    font-size: 1.4rem;
                    color: $neutral800;
                }

                #DatePickerComponent.datepicker-container {

                    .input-label {
                        margin-bottom: 0;
                    }

                    .container {
                        min-height: 4rem;
                    }
                }

            }



        }


    }

    .footer-pagination {
        position: sticky;
        top: 100%;
        right: 0%;
        box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.04);
        height: 6rem;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;


        .pagination-container {
            .buttons {
                border-radius: 0.4rem;
                background-color: $light;
            }

            .next-button {
                background-color: $primary500;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .previous-button {
                transform: rotate(180deg);
                background-color: $primary500;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .page-active {
                border: 1px solid $primary100;
                color: $primary500;
            }

        }
    }
}