@import "../../../DesignSystem/library.scss";

.benchmarking-body-container {
  width: 100%;
  padding: 0rem 2.7rem;
  height: 80vh;
  overflow-y: scroll;
  margin-top: 2rem;

  .no-data-msg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -5rem;

    .no-data-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
    }

    .msg-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      b:first-child {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.256px;
      }
      b:nth-child(2) {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.16px;
      }
      margin-top: -10rem;
    }
  }

  .benchmarks-cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
    gap: 2rem;
  }
}
