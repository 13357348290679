// this styles file is particularly used for global styling only, don't write any components related styling in this file
// @import './Constant/StyleAssets/color.scss';

@import './DesignSystem/classes';

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.cursorPointer {
    cursor: pointer;
}

.disableSelect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cursorDisable {
    cursor: not-allowed;
}

.noScrollBar::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.p-0 {
    padding: 0;
}

.m-0 {
    margin: 0;
}

.link {
    // color: #FF2572;
    // cursor: pointer;

    // &:hover {
    //     text-decoration: 1px solid underline #FF2572;

    // }
}


input {
    outline: none;
    border: none;

    &::placeholder {
        color: #858585;
    }
}

button[type='button'] {
    outline: none;
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

// hr {
//     border: 0.15px solid $border;
//     opacity: 30%;
// }


.shadow-30 {
    box-shadow: 3px 3px 10px rgba(88, 88, 88, 0.1);
}

.shadow-50 {
    box-shadow: 3px 3px 10px rgba(88, 88, 88, 0.5);

}


.disableImgSelect {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}