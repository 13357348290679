@import "../../../../DesignSystem/library";

.financed-emission-asset-class-component {
  padding: 2rem;
  overflow-y: scroll;

  .header-filter-row {
    margin-top: 1rem;
    .header {
      display: flex;
      justify-content: space-between;
      color: $neutral800;
      margin-bottom: 1rem;
      font-size: 22px;
      font-weight: 500;
      margin-left: 1rem;

      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-size: 12px;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
    }

    .filter {
      align-self: center;
      justify-self: end;
    }
  }

  .demographic-container {
    margin: 2rem 0;

    .demographic-tile {
      @include tile-white;

      .asset-class-partitioner {
        border-bottom: 1px solid #e2e2e2;
      }

      .asset-class-tabbed-component {
        height: 30px;
        margin-bottom: 1rem;

        .asset-class-toggle-switch-btn {
          font-size: 1.3rem !important;
          padding: 0rem;
        }
        .asset-class-toggle-switch-btn-active {
          color: $primary500;
          border: 1px solid $primary500;
        }

        .asset-class-toggle-switch-credit {
          background-color: white !important;
        }

        .asset-class-toggle-switch-btn-credit {
          font-size: 1.3rem !important;
          padding: 0rem 0.2rem !important;
          text-wrap: nowrap;
        }
        .asset-class-toggle-switch-btn-active-credit {
          color: $primary500;
          border: 1px solid $primary500;
          background-color: white !important;
        }

        .asset-class-toggle-switch-btn-credit {
          background-color: e2e2e2 !important;
          font-size: 1.3rem !important;
          padding: 0rem;
        }
      }

      .chart-header {
        margin: 1.2rem;
        font-weight: 500;
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }

      .asset-class-filter-dropdowns {
        display: flex;
        margin: 1rem;
        margin-bottom: 1.5rem;

        .asset-class-dropdown-1,
        .asset-class-dropdown-2 {
          width: 30%;
        }

        .asset-class-dropdown-2 {
          margin-left: 3rem;
        }
      }
    }

    .stats-row {
      column-gap: 3rem;

      .horizontally-aligned-cards {
        display: flex;
        justify-content: space-around;
        width: 100%;
      }
    }
  }
}
