@import "../../../../DesignSystem/library";

.data-collection-container {
  overflow-y: hidden;
}

#DataCollectionTableComponent.data-collection-tables {
  width: 100%;
  height: inherit;

  .header-row-container {
    position: relative;

    .filter-modal {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: $light;
      padding: 0.8rem;
      border-radius: 0.8rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1.2rem;
      z-index: 1;
      .Location-Dropdown-Container {
        min-width: 20rem;
      }

      .location-dropdown-body {
        top: 44%;
      }

      .dropdown-close-btn {
        position: absolute;
        bottom: 1%;
        right: 1%;
      }
    }

    .header-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $background-gray;
      padding: 1.4rem 1.4rem 0.8rem 1.4rem;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;
        color: $font-primary;

        svg {
          height: 3rem;
          width: 3rem;

          path {
            fill: $font-primary;
          }
        }

        .back {
          font-size: 1.8rem;
        }
      }

      .action-btns {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        .primary-button,
        .primary-no-outline {
          font-size: 1.4rem;
        }

        .plus-icon {
          path {
            fill: $light;
            stroke: $light;
          }
        }
      }
    }

    .search-bar-header-h2 {
      width: 100%;
      margin-top: 1.2rem;
      padding-right: 0.4rem;

      .header {
        font-size: 2rem;
        font-weight: 500;
        padding-left: 1.4rem;
      }

      .search-filter {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.2rem;
        padding-right: 1.4rem;

        .search {
          width: 30%;
        }

        .filter {
          .filter-btn {
            border: 1px solid $neutral400;
            font-size: 1.4rem;
            color: $neutral500;
          }
        }
      }
    }
  }

  .table-body {
    overflow-x: scroll;
  }

  .footer-action-btns {
    width: 100%;

    min-height: 6rem;
    background-color: $light;
    position: sticky;
    top: 92%;
    border-radius: 1.2rem;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.2rem;

    .pagination-container {
      .buttons {
        border-radius: 0.4rem;
        background-color: $light;
      }

      .next-button {
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .previous-button {
        transform: rotate(180deg);
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .page-active {
        border: 1px solid $primary100;
      }
    }
  }

  .asset-inventory-popup-edit,
  .asset-inventory-popup-delete {
    display: flex;
    align-items: center;
    gap: 0.9rem;
    font-size: 12px;
    cursor: pointer;
  }

  .asset-inventory-popup-delete {
    color: red;
    margin-top: 1rem;
  }
}
