@import '../../../../../../DesignSystem/library';

#dialogue-component.dialogue-modal-container {
    max-width: 80rem;
    width: 80rem;

    .dialogue-body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .text-type-input-container {
            margin-top: 1rem;
        }

        .input-label {
            font-size: 1.4rem;
        }

        .tile-divider {
            margin: 1.2rem 0;
            width: 100%;
            display: flex;
            color: $font-primary;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;

            hr {
                width: 45%;
                border: 0.5px solid $neutral50;
                height: 1px;
            }
        }

        .drag-drop-zone {
            border-radius: 1.2rem;
            background-color: $background-gray-light;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E0E0E0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 1.8rem;
            padding: 1.2rem 1.8rem;
            margin: 1.2rem 0;
            min-height: 16rem;

            .header {
                @include container-flex;
                justify-content: flex-start;
                font-size: 1.8rem;
                // color: black;
                font-weight: 500;
            }

            hr {
                border: 1px solid $light;
            }

            .sub-header {
                font-size: 1.4rem;
                margin-bottom: 0.8rem;
            }

            .links {
                font-size: 1.4rem;
                font-weight: 500;

                label {
                    color: $primary500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }



    }
    

}