@import "../../../../../DesignSystem/library";

#pcafMappingComponent.pcaf-component-container {
  .wrapper-in-data-validation {
    margin: 0;
    height: calc(100dvh - 18rem);
    padding: 2rem;

    .data-validation-body {
      position: relative;

      .pcaf-filter-header-section {
        padding: 1.5rem 2.5rem;
        display: flex;
        justify-content: space-between;

        .pcaf-dropdown-container {
          background-color: #fbfbfc;
          border: 1px solid #f0f0f0;
          width: 110%;
          font-size: 12px;
        }

        .pcaf-dropdown-body {
          min-height: 10rem;
          max-height: 15rem;
          overflow-y: scroll;
          margin-top: 1rem;
          width: 110%;
          left: 1%;
        }

        .section-left,
        .section-right {
          display: flex;
          gap: 1.2rem;
          align-items: center;
        }

        .section-left {
          margin-left: 1rem;
          margin-top: 1rem;
          .section-headline {
            font-size: 1.4rem;
            font-weight: 500;
          }

          .section-headline-2 {
            margin-left: 1.2rem;
            padding-left: 0.8rem;
            border-left: 0.4rem solid $primary500;

            span {
              color: $primary500;
              background-color: $primary50;
              padding: 0.4rem 0.8rem;
              border-radius: 0.4rem;
              margin-left: 0.8rem;
            }
          }
        }

        .section-right {
          gap: 1.6rem;

          .duration-section {
            background-color: $primary50;
            padding: 0.4rem 0.8rem;
            border-radius: 0.4rem;
            margin-left: 0.8rem;

            span {
              color: $primary500;
            }
          }
        }

        .primary-border-outlined {
          height: unset;
          padding: 0.4rem 0.8rem;
        }
      }

      .header-section-sub {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 1.2rem;

        .stats {
          display: flex;
          justify-content: flex-start;
          gap: 1.2rem;

          .stat-tile {
            padding: 1.2rem 1.8rem;
            border-radius: 1.2rem;

            .icon-container {
              height: 2.4rem;
              width: 2.4rem;
              background-color: $light;
              @include container-flex;
              border-radius: 0.4rem;
            }

            .content {
              display: flex;
              align-items: center;
              gap: 0.8rem;
            }
          }

          .stats-yellow {
            border: 1px solid #fdefc6;
            background: #fef7e3;
          }

          .stats-green {
            border: 1px solid #2dd03d29;
            background: #e6f9e8;
          }

          .stats-blue {
            border: 1px solid rgba(12, 140, 233, 0.16);
            background: rgba(12, 140, 233, 0.12);
          }
        }

        .search-inputs {
          display: flex;
          align-items: center;
          gap: 1.2rem;
        }
      }

      .table-container {
        // height: 100%;
        overflow: scroll;
      }
    }
  }

  .footer-action-btns {
    width: 100%;
    min-height: 6rem;
    background-color: $light;
    position: sticky;
    bottom: 1.2rem;
    border-radius: 1.2rem;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pagination-container {
      .buttons {
        border-radius: 0.4rem;
        background-color: $light;
      }

      .next-button {
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .previous-button {
        transform: rotate(180deg);
        background-color: $primary500;

        svg {
          path {
            fill: white;
          }
        }
      }

      .page-button {
      }

      .page-active {
        border: 1px solid $primary100;
      }

      .pagination-container {
      }
    }
  }

  .upload-file-investee-modal {
    width: 100%;
    min-height: 100%;

    .sample-content {
      @include container-flex;
      justify-content: flex-end;
      margin-left: auto;
      font-size: 1.2rem;
      color: $primary500;
    }

    .drag-drop-zone {
      border-radius: 1.2rem;
      background-color: $background-gray-light;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23E0E0E0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 1.2rem;
      padding: 1.2rem 1.8rem;
      margin: 1.2rem 0;

      .header {
        @include container-flex;
        justify-content: flex-start;
        font-size: 1.8rem;
        color: black;
        font-weight: 500;
      }

      hr {
        border: 1px solid $light;
      }

      .sub-header {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
      }

      .links {
        font-size: 1.4rem;
        font-weight: 500;

        label {
          color: $primary500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .tile-divider {
      width: 100%;
      display: flex;
      color: $font-primary;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;

      hr {
        width: 45%;
        border: 0.5px solid $neutral50;
        height: 1px;
      }
    }
  }

  .popup-checkbox-container {
    .popup-checkbox-entry {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    height: 25rem;
    overflow-y: scroll;
    width: 18rem;
  }
}
