@import '../../../../DesignSystem/library';

#dataCollectionComponent.dataCollectionComponent {
    // margin: 1.4rem 0;
    padding: 1.4rem;
    margin-bottom: 5rem;
    border-radius: 1.2rem;

    .section-header {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 0.8rem;
    }


    .toggle-switch-component {
        width: 40%;
        margin: 0;
        margin-left: auto;
    }

    .dataCollectionComponent {
        background-color: $light;
        border-radius: 1.2rem;
        padding: 1.2rem;
        overflow-y: scroll;
        height: calc(100dvh - $layout-header-height - 4rem);
    }
}