@import "../../../../DesignSystem/library";

#TargetSettingFormComponent.target-setting-form-container {
  width: 100%;

  .wrapper-container {
    padding: 2rem;
    color: $neutral800;
    overflow-y: scroll;
    height: 90dvh;

    .header-row-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $neutral200;

      .section-name {
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.2rem;

        .chevron-icon {
          height: 2.8rem;
          width: 2.8rem;

          path {
            fill: $neutral800;
          }
        }
      }

      .poweredby-details {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .label {
          color: $neutral400;
        }

        img {
          mix-blend-mode: darken;
          height: 3rem;
        }
      }
    }

    .tile {
      margin: 1.2rem 0;
      background-color: $light;
      border: 1px solid $neutral50;
      padding: 1.2rem 2.4rem;
      border-radius: 1.2rem;
      width: 80%;

      .radio-selects {
        display: flex;
        width: 100%;
        gap: 1.2rem;
      }

      .radio-selects-location {
        display: flex;
        width: 30%;
        gap: 1.2rem;
      }

      .header {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 1.2rem 0;
      }
    }

    .dropdown-selection {
      display: flex;
      justify-content: flex-start;
      gap: 2rem;

      .section {
        width: 100%;

        .ts-baseline-year-dropdown {
          background-color: #f4f5f7;
          width: 120%;
        }

        .ts-baseline-year-dropdown-body {
          width: 120%;
          left: 1%;
          margin-top: 1rem;
          height: 10rem;
          overflow-y: scroll;
        }
      }
    }

    .deflex {
      display: block;

      .header {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 1.2rem 0;
      }

      .sub-header {
        font-size: 1.4rem;
        color: $neutral400;
        border-bottom: 1px solid $neutral200;
        margin: 1.2rem 0;
        margin-top: 2rem;
      }

      .radio-selects {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 4rem;
      gap: 1rem;
    }
  }
}
